import React, { FC } from 'react'
import RedemptionModalProps from './RedemptionModal.interface'
import styles from './RedemptionModal.module.scss'
import InfoIcon from "../Icon/Info"
import BasicModalWrapper from '../BasicModalWrapper/BasicModalWrapper'
import classNames from 'classnames'

const RedemptionModal: FC<RedemptionModalProps> = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
    <InfoIcon 
        className={styles.icon}
        onClick={() => setIsOpen(true)}
    />
    <BasicModalWrapper
      isOpen={isOpen}
      onClose={() => {setIsOpen(false)}}
      isContentRightMargin={true}
    >
      <div
        className={classNames(styles.root, styles.rootAddRightGap)}
      >
        <div
          className={`${styles.content}`}
        >
          <div className={`${styles.block} pt-0`}>
            <h2 className={`${styles.headline}`}>Redemptions</h2>
            <p className={`${styles.text} py-2`}>Redemptions are when you customer redeems their offer using their card that they acquired though their offer.</p>
            <p className={`${styles.text} py-2`}>Redemptions give you insight on when your new customer actually engaged with your business!</p>


          </div>
          <div className={`${styles.block} bg-mutedBlue`}>
            <h2 className={`${styles.headline}`}>Opt Ins</h2>
            <p className={`${styles.text}`}>
              Opt ins are when a customer has acquired your offer by scanning your custom QR code for your offer and received a card and is now a potential customer. This is the first step in the process of acquiring a new customer.
              
            </p>
          </div>

          <div className={`${styles.block} pb-0`}>
            <h2 className={`${styles.headline}`}>Card Load Amount</h2>
            <p className={`${styles.text} py-2`}>The card load amount is how much has been allocated to the card for your offer for the new customer go out and spend.</p>

          </div>
        </div>
      </div>
    </BasicModalWrapper>
  </>
  )
}

export default RedemptionModal
