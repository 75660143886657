import { FC, useCallback } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import MultiSelectProps from './MultiSelect.interface'
import styles from './MultiSelect.module.scss'

const animatedComponents = makeAnimated()

const MultiSelect: FC<MultiSelectProps> = ({
  label,
  description,
  placeholder,
  errorMessage,
  options,
  isError,
  isRequired,
  value,
  onChange,
  innerRef,
  name,
}) => {
  const handleChange = useCallback(
    (newValue: any) => {
      onChange?.(newValue)
    },
    [onChange],
  )

  return (
    <div className={styles.root}>
      <span className={`${styles.title} ${isError && styles['title-error']}`}>
        {label} {isRequired ? '*' : ''}
      </span>
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={options}
        className={`${styles.select} ${isError && styles['select-error']}`}
        placeholder={placeholder}
        onChange={handleChange}
        value={onChange ? value : undefined}
        name={name}
        ref={innerRef}
      />
      {isError && (
        <>
          <span className={styles.error}>{errorMessage}</span>
          <br />
        </>
      )}
      <span className={styles.description}>{description}</span>
    </div>
  )
}

export default MultiSelect
