import React, { createContext, useState, useContext } from "react"

interface UseTheme {
  mode: "light" | "dark",
  setMode: React.Dispatch<React.SetStateAction<"light" | "dark">>
}

type Props = {
  children?: React.ReactNode
}

export const themeContext = createContext<UseTheme>({} as UseTheme)
const useThemeProvider = (): UseTheme => {
  const [mode, setMode] = useState<"light" | "dark">((localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) ? "dark" : "light");

  return {
    mode,
    setMode
  }
}

export const ThemeProvider: React.FC<Props> = ({ children }) => {
  const theme = useThemeProvider()
  return (
    <themeContext.Provider value={theme}>
      {children}
    </themeContext.Provider>
  )
}

export const useTheme = () => {
  const theme = useContext(themeContext)
  if (!theme) {
    throw new Error('useTheme must be used within an ThemeProvider')
  }
  return theme
}