import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const SuccessIcon: FunctionComponent<IconProps> = ({
  color = '#64CE5B',
  width = 69,
  height = 72,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 69 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M34.5 5.96722C50.3527 5.96722 63.25 19.3517 63.25 35.8033C63.25 52.255 50.3527 65.6394 34.5 65.6394C18.6473 65.6394 5.75 52.255 5.75 35.8033C5.75 19.3517 18.6473 5.96722 34.5 5.96722ZM34.5 0C15.4474 0 0 16.0309 0 35.8033C0 55.5757 15.4474 71.6067 34.5 71.6067C53.5526 71.6067 69 55.5757 69 35.8033C69 16.0309 53.5526 0 34.5 0ZM45.1317 45.8104C43.9674 45.7179 44.0766 44.599 45.1777 44.3872C47.0005 44.0381 47.8026 43.0237 47.8026 42.0868C47.8026 41.2962 47.2334 40.5622 46.2242 40.3235C45.0225 40.0401 45.2697 39.1927 46.2702 39.1122C47.8975 38.9779 48.6967 37.9724 48.6967 37.0087C48.6967 36.1673 48.0873 35.3558 46.8855 35.1887C45.6521 35.0186 46.0546 34.0937 46.9315 33.8133C47.6819 33.5746 48.8779 33.0674 48.8779 31.5606C48.8779 30.5581 48.1591 29.2304 45.6119 29.3378C43.8466 29.4124 40.342 29.0425 38.456 28.0191C39.7555 22.5621 39.4421 14.9181 36.0266 14.9181C33.7353 14.9181 33.3011 17.6123 32.6111 20.0916C30.1415 28.9977 25.2023 30.6298 20.125 31.4353V46.246C29.739 46.246 32.3437 50.7214 39.537 50.7214C44.137 50.7214 46.5117 48.1346 46.5117 46.7442C46.5117 46.246 46.1207 45.8909 45.1317 45.8104V45.8104Z"
      fill={color}
    />
  </svg>
)

export default SuccessIcon
