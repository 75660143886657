"use client"

import { FC, useState } from 'react';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';

interface TableProps {
    columns: string[];
    data: { [key: string]: string | number }[];
    itemsPerPage: number,
    totalItems: number,
    onNextPage?: () => void;
    onPrevPage?: () => void;
    loading: boolean
    view?: {
      label: string,
      path: string
    }
  }

const Table: FC<TableProps> = ({ columns, data, itemsPerPage, totalItems, onNextPage, onPrevPage, loading, view }) => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const startItemNumber = (currentPage - 1) * itemsPerPage + 1;
    const endItemNumber = Math.min(currentPage * itemsPerPage, totalItems);
    // Calculate items displayed till the current page
    const itemsDisplayedTillNow = currentPage * itemsPerPage;

  return (
    <main>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full typo-p-small text-left text-gray-500 dark:text-gray-400">
          <thead className="typo-p-small text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            
            <tr>
              {view && 
              <th key={view.path} scope="col" className="px-6 py-3">
                View
              </th>}
              {columns.map((col, index) => {
                if (col !== 'id' || !view) {
                  return (
                    <th key={index} scope="col" className="px-6 py-3">
                      {col}
                    </th>
                  )
                }
              })}
            </tr>
          </thead>
          <tbody>
            {loading ? <tr><td colSpan={columns.length} ><div className='flex justify-center my-20'><Spinner /></div></td></tr> : <>
            {data.length == 0 ? 
            <tr><td colSpan={columns.length}>
              <h1 className='my-20 text-center'>No data available</h1>
            </td></tr> : data.map((row, rowIndex) => (
              <tr key={rowIndex} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                <>
                {view && <td className="px-6 py-4 text-blue-600"><Link to={`${view.path}/${row.id}`}>{view.label}</Link></td>}
                {columns.map((col, colIndex) => {
                  if (col !== 'id' || !view) {
                    return (
                      <td key={colIndex} className="px-6 py-4">
                        {String(row[col])}
                      </td>
                    )
                  }
                })}
                </>
              </tr>
            ))}
            </>}
          </tbody>
        </table>
        
      </div>
      {(!loading && data.length != 0) && <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
          <span className="typo-p-small font-normal text-gray-500 dark:text-gray-400">
            Showing <span className="font-semibold text-gray-900 dark:text-white">{startItemNumber}-{endItemNumber}</span> of <span className="font-semibold text-gray-900 dark:text-white">{totalItems}</span>
          </span>
          <ul className="inline-flex -space-x-px typo-p-small h-8">
            <li>
              <button onClick={() => {
                onPrevPage && onPrevPage()
                setCurrentPage(currentPage-1)
              }} disabled={currentPage === 1} className={`flex items-center justify-center p-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'dark:hover:bg-gray-700 dark:hover:text-white'}`}>Previous</button>
            </li>
            <li>
              <button onClick={() => {
                onNextPage && onNextPage()
                setCurrentPage(currentPage+1)
              }} disabled={itemsDisplayedTillNow >= totalItems} className={`flex items-center justify-center p-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 ${itemsDisplayedTillNow >= totalItems ? 'opacity-50 cursor-not-allowed' : 'dark:hover:bg-gray-700 dark:hover:text-white'}`}>Next</button>
            </li>
          </ul>
        </nav>}
    </main>
  );
}

export default Table;
