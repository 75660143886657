import { FC } from 'react'
import OfferInfoCardProps from './OfferInfoCard.interface'
import styles from './OfferInfoCard.module.scss'
import classNames from 'classnames'
import { formatDate } from '../../utils/data'

const OfferInfoCard: FC<OfferInfoCardProps> = ({
  customMessageRequest,
  customMessageResponse,
  descriptionText,
  endDate,
  multiMediaImageUrl,
  startDate,
  maxOptIns
}) => {
  return (
    <div className={`${styles.root}`}>
    <div className={`${styles.titleWrapper}`}>
      <div className={`${styles.title}`}>
        Offer Information
      </div>
    </div>
    <div className={styles.contentContainer}>
      <div className="grid grid-cols-2 p-4 gap-4">
        <p className={styles.infoText}>Description</p>
        <p className={styles.infoText}>{descriptionText  ? `${descriptionText}` : 'N/A'}</p>
        <p className={styles.infoText}>Start Date</p>
        <p className={styles.infoText}>{formatDate(startDate)}</p>
        <p className={styles.infoText}>End Date</p>
        <p className={styles.infoText}>{formatDate(endDate)}</p>
        <p className={styles.infoText}>Custom Message Request</p>
        <p className={styles.infoText}>{customMessageRequest}</p>
        <p className={styles.infoText}>Custom Message Response</p>
        <p className={styles.infoText}>{customMessageResponse}</p>
        <p className={styles.infoText}>Max Opt Ins</p>
        <p className={styles.infoText}>{maxOptIns}</p>
      </div>
        {multiMediaImageUrl && <img src={multiMediaImageUrl} className="h-80  my-5 my-10 mx-auto" alt="Jib Logo" />}
    </div>
  </div>
  )
}

export default OfferInfoCard
