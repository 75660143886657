
import StripeCheckoutForm from "../../components/StripeCheckoutForm"
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import Card from "../../components/card";
import { useQuery } from "urql";
import { getPublishableKeyQuery, getPaymentIntentQuery } from "../../graphql/queries";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTheme } from "../../providers/Theme";
import styles from "./checkout.module.scss"
import { toUsd } from "../../utils/data";

type CheckoutParams = {
  id: string
}


export default function Checkout() {
  const { mode } = useTheme()
  const [clientSecret, setClientSecret] = useState("");
  const { id } = useParams<keyof CheckoutParams>() as CheckoutParams
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(Promise.resolve(null))
  const [processingFee, setProcessingFee] = useState<number>(0)


  const [{ data: publishableKey, fetching: _publishableKeyFetching, error: publishableKeyError }] = useQuery({
    query: getPublishableKeyQuery,
  })
  const [{ data: paymentIntent, fetching: _paymentIntentFetching, error: paymentIntentError }] = useQuery({
    query: getPaymentIntentQuery,
    variables: { id },
  })

  useEffect(() => {
    if(publishableKey?.getPublishableKey.__typename === "QueryGetPublishableKeySuccess") {
      setStripePromise(loadStripe(publishableKey.getPublishableKey.data.publishableKey))
    }
  }, [publishableKey])

  useEffect(() => {
    if(paymentIntent?.getPaymentIntent.__typename === "QueryGetPaymentIntentSuccess") {
      setClientSecret(paymentIntent.getPaymentIntent.data.clientSecret ?? "")
    }
  }, [paymentIntent])

  useEffect(() => {
    let errorMessage = ""
    if (publishableKey?.getPublishableKey.__typename === "BaseError") errorMessage += publishableKey.getPublishableKey.message;
    if (paymentIntent?.getPaymentIntent.__typename === "BaseError") errorMessage += paymentIntent.getPaymentIntent.message;
    if (errorMessage) {
      toast.error(errorMessage)
      console.error(errorMessage)
    }
  }, [publishableKey?.getPublishableKey, paymentIntent?.getPaymentIntent])

  useEffect(() => {
    let errorMessage = ""
    if (publishableKeyError) errorMessage += publishableKeyError.message;
    if (paymentIntentError) errorMessage += paymentIntentError.message;
    if (errorMessage) {
      toast.error(errorMessage)
      console.error(errorMessage)
    }
  }, [publishableKeyError, paymentIntentError])


  return (
    <Card className={styles.root}>
      <div className="flex justify-between pb-4">
        <p className='typo-h2 dark:text-white'>Checkout</p>
        <p className='typo-h2 dark:text-white'>{paymentIntent?.getPaymentIntent.__typename === "QueryGetPaymentIntentSuccess" && toUsd(paymentIntent.getPaymentIntent.data.amount + processingFee)}</p>
      </div>
      {(clientSecret && publishableKey) && (
        <Elements options={{clientSecret, appearance: {theme: mode === "dark" ? "night" : "stripe"}}} stripe={stripePromise}>
          <StripeCheckoutForm
          />
        </Elements>
      )}
    </Card>
  )
}