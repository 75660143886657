export const formatDate = (date: string) => {
  const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false
    };
  const d = new Date(date)
  return d.toLocaleDateString('en-US', options as any)
}

export const toUsd = (num: number, sigFigs?: number) => {
  const toUsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: sigFigs,
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return toUsdFormatter.format(num)
}

export const handleFilter = (
  key: string, 
  value: boolean, 
  filterArray: string[], 
  setFilterArray: (newArray: string[]) => void
) => {
  if (filterArray.includes(key) && !value) {
      setFilterArray(filterArray.filter(item => item !== key));
  } else if (!filterArray.includes(key) && value) {
      setFilterArray([...filterArray, key]);
  }
}
