import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const CloseCircleIcon: FunctionComponent<IconProps> = ({
  color = '#26346A',
  width = 57,
  height = 57,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} fill-${color}`}
  >
    <circle cx="28.5" cy="28.5" r="28.5" fill="#80D4F3" />
    <path
      d="M33.4977 35.7558L28.678 30.9361L23.7905 35.8237L21.8672 33.9003L26.7547 29.0128L21.9577 24.2158L23.7 22.4735L28.497 27.2705L33.4072 22.3604L35.3305 24.2837L30.4203 29.1938L35.24 34.0135L33.4977 35.7558Z"
      fill={color}
    />
  </svg>
)

export default CloseCircleIcon
