import React, { FC } from 'react'
import IconProps from './Icon.interface'

const MasterCardIcon: FC<IconProps> = ({
  width = 46,
  height = 28,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 46 28"
    className={className}
  >
    <g clipPath="url(#clip0_1891_34026)">
      <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" fill="#E8E8E8" />
      <path d="M3.5 1C2.83696 1 2.20107 1.26339 1.73223 1.73223C1.26339 2.20107 1 2.83696 1 3.5L1 24.5C1 25.163 1.26339 25.7989 1.73223 26.2678C2.20107 26.7366 2.83696 27 3.5 27H42.5C43.163 27 43.7989 26.7366 44.2678 26.2678C44.7366 25.7989 45 25.163 45 24.5V3.5C45 2.83696 44.7366 2.20107 44.2678 1.73223C43.7989 1.26339 43.163 1 42.5 1L3.5 1Z" fill="white" />
    </g>
    <path d="M27.9681 5.36395H17.9646V21.7456H27.9681V5.36395Z" fill="#FF5F00" />
    <path d="M19.0389 13.5548C19.0389 10.3322 20.5159 7.31098 23 5.36399C18.5018 1.80568 11.9223 2.61134 8.36396 7.10957C4.80565 11.6078 5.61131 18.1873 10.1095 21.7456C13.8693 24.6997 19.1731 24.6997 23 21.7456C20.5159 19.7315 19.0389 16.7103 19.0389 13.5548Z" fill="#EB001B" />
    <path d="M38.8445 20V19.6643H38.9788V19.5971H38.6431V19.6643H38.7773L38.8445 20ZM39.5159 20V19.5971H39.3816L39.2473 19.8657L39.113 19.5971H39.0459V20H39.113V19.6643L39.2473 19.9328H39.3144L39.4487 19.6643V20H39.5159Z" fill="#F79E1B" />
    <path d="M39.8516 13.5548C39.8516 19.3287 35.2191 23.9612 29.4452 23.9612C27.0954 23.9612 24.8127 23.1555 23 21.7456C27.4982 18.1873 28.3039 11.6749 24.7456 7.10957C24.2085 6.43819 23.6714 5.90109 23 5.36399C27.4982 1.80568 34.0777 2.61134 37.636 7.10957C39.0459 8.92229 39.8516 11.205 39.8516 13.5548Z" fill="#F79E1B" />
    <defs>
      <clipPath id="clip0_1891_34026">
        <rect width="46" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default MasterCardIcon
