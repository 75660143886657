import { FC } from 'react'
import IconProps from './Icon.interface'

const Calendar: FC<IconProps> = ({
  color = '#26346A',
  width = 24,
  height = 24,
  className,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 20H16V16H20V20ZM14 10H10V14H14V10ZM20 10H16V14H20V10ZM8 16H4V20H8V16ZM14 16H10V20H14V16ZM8 10H4V14H8V10ZM24 2V24H0V2H3V3C3 4.103 3.897 5 5 5C6.103 5 7 4.103 7 3V2H17V3C17 4.103 17.897 5 19 5C20.103 5 21 4.103 21 3V2H24ZM22 8H2V22H22V8ZM20 1C20 0.448 19.553 0 19 0C18.447 0 18 0.448 18 1V3C18 3.552 18.447 4 19 4C19.553 4 20 3.552 20 3V1ZM6 3C6 3.552 5.553 4 5 4C4.447 4 4 3.552 4 3V1C4 0.448 4.447 0 5 0C5.553 0 6 0.448 6 1V3Z"
      fill={color}
    />
  </svg>
)

export default Calendar
