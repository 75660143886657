import React, { FC } from 'react'
import IconProps from './Icon.interface'

const AmericanExpressIcon: FC<IconProps> = ({
  width = 46,
  height = 28,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 46 28"
    className={className}
  >
    <mask id="path-1-inside-1_1891_34008" fill="white">
      <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" />
    </mask>
    <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" fill="white" />
    <path d="M3.5 28V27V28ZM0 24.5H-1H0ZM3.5 0V-1V0ZM46 24.5H45H46ZM42.5 27H3.5V29H42.5V27ZM3.5 27C2.83696 27 2.20107 26.7366 1.73223 26.2678L0.318019 27.682C1.16193 28.5259 2.30653 29 3.5 29V27ZM1.73223 26.2678C1.26339 25.7989 1 25.163 1 24.5H-1C-1 25.6935 -0.525894 26.8381 0.318019 27.682L1.73223 26.2678ZM1 24.5V3.5H-1V24.5H1ZM1 3.5C1 2.83696 1.26339 2.20107 1.73223 1.73223L0.318019 0.318019C-0.525894 1.16193 -1 2.30653 -1 3.5H1ZM1.73223 1.73223C2.20107 1.26339 2.83696 1 3.5 1V-1C2.30653 -1 1.16193 -0.525894 0.318019 0.318019L1.73223 1.73223ZM3.5 1H42.5V-1H3.5V1ZM42.5 1C43.163 1 43.7989 1.26339 44.2678 1.73223L45.682 0.318019C44.8381 -0.525894 43.6935 -1 42.5 -1V1ZM44.2678 1.73223C44.7366 2.20107 45 2.83696 45 3.5H47C47 2.30652 46.5259 1.16193 45.682 0.318019L44.2678 1.73223ZM45 3.5V24.5H47V3.5H45ZM45 24.5C45 25.163 44.7366 25.7989 44.2678 26.2678L45.682 27.682C46.5259 26.8381 47 25.6935 47 24.5H45ZM44.2678 26.2678C43.7989 26.7366 43.163 27 42.5 27V29C43.6935 29 44.8381 28.5259 45.682 27.682L44.2678 26.2678Z" fill="#E8E8E8" mask="url(#path-1-inside-1_1891_34008)" />
    <mask id="mask0_1891_34008" maskUnits="userSpaceOnUse" x="1" y="1" width="44" height="26">
      <path d="M3.5 1C2.83696 1 2.20107 1.26339 1.73223 1.73223C1.26339 2.20107 1 2.83696 1 3.5L1 24.5C1 25.163 1.26339 25.7989 1.73223 26.2678C2.20107 26.7366 2.83696 27 3.5 27H42.5C43.163 27 43.7989 26.7366 44.2678 26.2678C44.7366 25.7989 45 25.163 45 24.5V3.5C45 2.83696 44.7366 2.20107 44.2678 1.73223C43.7989 1.26339 43.163 1 42.5 1L3.5 1Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1891_34008)">
      <path d="M41.2519 26.2148L39.2963 23.8519L37.2593 26.2148H33.3481H24.7926V15.2148H20.8L25.7704 2.91111H30.5778L32.2889 7.14815V2.91111H38.237L39.2963 6.08889L40.3556 2.91111H44.9185V1.03704C44.9185 -0.103704 44.1037 -1 43.0444 -1H2.87407C1.81481 -1 1 -0.103704 1 1.03704V27.5185C1 28.6593 1.81481 29.5556 2.87407 29.5556H43.0444C44.1037 29.5556 44.9185 28.6593 44.9185 27.5185V26.2148H41.2519Z" fill="#0071CE" />
      <path d="M41.8219 24.9926H44.9997L40.8441 20.1852L44.9997 15.3778H41.9034L39.296 18.4741L36.7701 15.3778H33.5923L37.7478 20.1852L33.5923 24.9926H36.6886L39.296 21.8963L41.8219 24.9926Z" fill="#0071CE" />
      <path d="M28.5406 22.7111V21.2444H33.511V19.1259H28.5406V17.5778H33.5925V15.3778H26.0962V24.9926H33.5925V22.7111H28.5406Z" fill="#0071CE" />
      <path d="M42.6367 13.9926H44.9182V4.45926H41.333L39.296 10.4074L37.3404 4.45926H33.5923V13.9926H35.9552V7.31111L38.2367 13.9926H40.3553L42.6367 7.31111V13.9926Z" fill="#0071CE" />
      <path d="M29.6812 4.45926H26.5849L22.6738 13.9926H25.2812L26.0146 12.1185H30.0886L30.822 13.9926H33.5923L29.6812 4.45926ZM26.9109 9.91852L28.1331 6.82222L29.3553 9.91852H26.9109Z" fill="#0071CE" />
      <path d="M42.8 19.8593L45 22.5482V17.2519L42.8 19.8593Z" fill="#0071CE" />
    </g>
  </svg>
)

export default AmericanExpressIcon
