import RadioSelect from "../../components/RadioSelect";
import Card from "../../components/card";
import { useState, useMemo, useEffect } from "react";
import { Transaction } from "../../graphql/gql/graphql";
import styles from "./user.module.scss";
import { 
  listOtherUserTransactionsQuery,
  listAccountsQuery,
  getUserQuery,
  listCardProgramsQuery,
  getPricingQuery,
  primaryAccountQuery,
  getOffersQuery
} from "../../graphql/queries"
import { addFundsToAccountMutation } from "../../graphql/mutations"
import Table from '../../components/Table'
import { useQuery, useMutation } from 'urql';
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/Auth";
import { toast } from "react-toastify";
import { toUsd, handleFilter } from "../../utils/data"
import CardPrograms from "../../components/CardPrograms";
import Pricing from "../../components/Pricing";
import Modal from "../../components/Modal";
import CurrencyInput from 'react-currency-input-field';
import Spinner from '../../components/Spinner';
import OfferCard from "../../components/OfferCard/OfferCard";
import { offerStateVariantMap } from "../../utils/offerToBadge";

export default function User() {
  const auth = useAuth()
  const navigate = useNavigate()
  /* State */
  const { userId } = useParams<{ userId: string }>();
  const [typeFilters, setTypeFilters] = useState<string[]>([])
  const [transactionOffset, setTransactionOffset] = useState<number>(0)
  const [addFundsModalIsOpen, setAddFundsModalIsOpen] = useState<boolean>(false)
  const [addFundsAmount, setAddFundsAmount] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /* Queries */
  const [{ data: transactions, fetching: transactionsFetching, error: transactionsError}] = useQuery({
    query: listOtherUserTransactionsQuery,
    variables: {
      offset: transactionOffset,
      pageNumber: 10,
      type: typeFilters,
      otherUserId: userId ?? ""
    },
    requestPolicy: "network-only"
  })
  const [{ data: pricing, fetching: pricingFetching, error: pricingError}, refetchPricing] = useQuery({
    query: getPricingQuery,
    variables: {
      id: userId
    },
    pause: auth.userData?.role !== "admin",
    requestPolicy: "network-only"
  })
  const [{ data: primaryAccount, fetching: primaryAccountFetching, error: errorFetching }, refetchPrimaryAccount] = useQuery({
    query: primaryAccountQuery,
    variables: {
      userId: userId ?? ""
    },
    pause: auth.userData?.role !== "admin",
    requestPolicy: "network-only"
  })
  const [{ data: accounts, fetching: accountsFetching, error: accountsError}] = useQuery({
    query: listAccountsQuery,
    variables: {
      otherUserId: userId ?? "",
      offset: 0,
      pageNumber: 10
    },
    requestPolicy: "network-only"
  })

  const [{ data: user, fetching: userFetching, error: userError}] = useQuery({
    query: getUserQuery,
    variables: {
      id: userId ?? ""
    },
    requestPolicy: "network-only"
  })
  const [{ data: cardPrograms, fetching: cardProgramsFetching, error: cardProgramError}] = useQuery({
    query: listCardProgramsQuery,
    variables: {
      offset: 0,
      pageNumber: 50,
      otherUserId: userId ?? undefined
    },
    requestPolicy: "network-only"
  })
  const [{ data: offersData, fetching: offersFetching, error: offersError }] = useQuery({
    query: getOffersQuery,
    variables: {
      offset: 0,
      pageNumber: 50,
      otherUserId: userId ?? undefined
    },
    pause: userId === undefined,
    requestPolicy: "network-only"
  })

  /* Mutations */
  const [, addFundsToAccount] = useMutation(addFundsToAccountMutation)

  /* Application Based Error Handling */
  useEffect(() => {
    let errorMessage = ""
    if (accounts?.accounts.__typename === "BaseError") errorMessage += `${accounts.accounts.message} `
    if (errorMessage) {
      toast.error(errorMessage, {position: "top-center"})      
      console.error(errorMessage)
    }
  }, [
    accounts?.accounts
  ])

   /* GraphQL Error Handling */
  useEffect(() => {
    if (accountsError) {
      console.error(accountsError)
      toast.error("GraphQlError", {position: "top-center"})
    }
  }, [
    accountsError
  ])

  /* Transaction Data */
    const TransactionKeys: (keyof Transaction)[] = useMemo(() => [
      "id",
      "timeCreated",
      "timeUpdated",
      "amount",
      "type",
    ], []);
    const filteredTransactions = useMemo(() => {
      if (!transactions?.otherUserTransactions) return [];
      if (transactions?.otherUserTransactions.__typename === "BaseError") return [];
      return transactions?.otherUserTransactions.data.transactions.map((obj: any) => {
        const filteredObj: any = {};
        TransactionKeys.forEach((key: keyof Transaction) => {
          if (key in obj) {
            filteredObj[key] = obj[key];
          }
        });
        return filteredObj;
      });
    }, [transactions?.otherUserTransactions, TransactionKeys]);
    const transactionsCount = useMemo(() => {
      if (!transactions?.otherUserTransactions) return 0;
      if (transactions.otherUserTransactions.__typename === "BaseError") {
        return 0
      }
      return transactions.otherUserTransactions.data.count;
    }, [transactions?.otherUserTransactions])

    /* Add Funds */
    const onAddFundsSubmit = async () => {
      setIsLoading(true)
      addFundsToAccount({
        id: primaryAccount?.primaryAccount.__typename === "QueryPrimaryAccountSuccess" ? primaryAccount?.primaryAccount.data.id : "",
        balance: parseFloat(addFundsAmount ?? "0"),
        userId: userId ?? ""
      }).then((result) => {
        if (result.data?.addFundsToAccount.__typename === "MutationAddFundsToAccountSuccess") {
          toast.success("Successfully added funds", {position: "top-center"})
        } else {
          toast.error("Failed to add funds", {position: "top-center"})
          console.error(result.data?.addFundsToAccount)
        }
        refetchPrimaryAccount()
      }).catch((error) => {
        console.error(error)
        toast.error("Failed to add funds", {position: "top-center"})
      }).finally(() => {
        setIsLoading(false)
        setAddFundsModalIsOpen(false)
      })
    }

  return (
    <div className={styles.root}>
      {(user?.getUser.role != "endUser" && auth.userData?.role === "admin") &&
      <CardPrograms 
        cardPrograms={cardPrograms?.cardPrograms}
        displayCreate={true}
        userId={userId}
        />}
       {(user?.getUser.role != "endUser" && auth.userData?.role === "admin") &&
        <Pricing 
          fetching={pricingFetching}
          pricing={pricing?.pricing}
          editable={true}
          userId={userId}
        />
      }
      <Card className="lg:mb-4 p-5">
        <div className="flex justify-between">
          <h5 className="mb-2 mr-5 typo-h3 font-bold tracking-tight text-gray-900 dark:text-white">User Details</h5>
        </div>
        <div>
          <p className="typo-p-medium text-gray-700 dark:text-gray-400">Email: {user?.getUser.email}</p>
          <p className="typo-p-medium text-gray-700 dark:text-gray-400">Name: {user?.getUser.firstName} {user?.getUser.lastName}</p>
          <p className="typo-p-medium text-gray-700 dark:text-gray-400">Phone Number: {user?.getUser.phoneNumber}</p>
          {auth.userData?.role === "admin" && <p className="typo-p-medium text-gray-700 dark:text-gray-400">Role: {user?.getUser.role}</p>}
        </div>
      </Card>
      {accounts?.accounts.__typename === "QueryAccountsSuccess" && accounts.accounts.data.accounts.map((account) => (
        <Card className="lg:mb-4 p-5" key={account.id}>
          <div className="flex justify-between">
            <h5 className="mb-2 mr-5 typo-h3 font-bold tracking-tight text-gray-900 dark:text-white">{account.type === "PRIMARY" ? "Primary Account" : `${account.cardProgram && `Account`}`}</h5>
           <div>
              <p className="typo-p-medium text-gray-700 dark:text-gray-400">{account.type}</p>
              {account.cardProgram && <p className="typo-p-medium text-gray-700 dark:text-gray-400">{account.cardProgram.name}</p>}
           </div>
          </div>
          <div>
            <p className="typo-p-medium text-gray-700 dark:text-gray-400">{toUsd(account.balance)}</p>
            
          </div>
        </Card>
      ))}
      {(auth.userData?.role === "admin" && primaryAccount?.primaryAccount.__typename === "QueryPrimaryAccountSuccess") &&  <Card className="lg:mb-4 p-5" key={primaryAccount?.primaryAccount.data.id}>
          <div className="flex justify-between">
            <h5 className="mb-2 mr-5 typo-h3 font-bold tracking-tight text-gray-900 dark:text-white">Primary Account</h5>
           <div>
           <p  onClick={() => setAddFundsModalIsOpen(true)} className="text-gray-700 dark:text-gray-400 typo-p-small hover:text-gray-400 hover:cursor-pointer dark:hover:text-gray-200">Add funds</p>
           </div>
          </div>
          <div>
            <p className="typo-p-medium text-gray-700 dark:text-gray-400">{toUsd(primaryAccount?.primaryAccount.data.balance)}</p>
            
          </div>
        </Card>}

        {offersData?.offers.__typename === "QueryOffersSuccess" && offersData.offers.data.offers.length > 0 ? 
        <>
          {offersData?.offers.data.offers.map((offer) => (
            <div className="col-span-2" key={offer.id}>
              <OfferCard 
                title={offer.name}
                budgetRemains={(offer.optIns/offer.maxOptIns) * 100}
                cardsCreated={`${offer.optIns}`}
                cardsRedeemed={`${offer.redemptions}`}
                cardsRedeemedPerCent={`${offer.optIns !== 0 ? offer.redemptions/offer.optIns * 100 : 0}`}
                topRightLinkCallback={() => navigate(`/offers/${offer.id}`)}
                variant={offerStateVariantMap(offer.status)}
                addFundsCallBack={() => console.log("TODO")}
                key={offer.id}
              />
            </div>
          ))}
        </> :
        <div className="col-span-2 flex mx-auto mb-4">
          <p className="typo-p-medium text-gray-700 dark:text-gray-400">No offers found</p>
      </div>}

      <Card className="col-span-2 p-4">
        <p className="typo-h2 dark:text-white mb-2">Transactions</p>
        <p className="text-grey dark:text-gray-300 typo-p-small mb-5">View your users transactions.
        {auth?.userData?.role === "admin" && `You are an admin, you can view all transactions, not just the transaction a user has for your card programs.`}
        </p>
        <div className="flex gap-4 pb-5">
        <RadioSelect 
          options={[
            {
              label: "Internal",
              value: "INTERNAL"
            },
            {
              label: "Card Transaction",
              value: "CARD_TRANSACTION"
            },
            {
              label: "Sms Fee",
              value: "SMS_FEE"
            },
            {
              label: "Card Create Fee",  
              value: "CARD_CREATE_FEE"
            },
            {
              label: "Card Transaction Fee",
              value: "CARD_TRANSACTION_FEE"
            },
            {
              label: "Card Transaction Void",
              value: "CARD_TRANSACTION_VOID"
            },
            {
              label: "Card Settled",
              value: "CARD_TRANSACTION_SETTLED"
            }
          ]}
          name="Filter by Type"
          handleClick={(key, value) => handleFilter(key, value, typeFilters, setTypeFilters)}
        />
        </div>
      <Table
          loading={transactionsFetching}
          columns={TransactionKeys}
          data={filteredTransactions}
          totalItems={transactionsCount}
          itemsPerPage={10}
          onNextPage={() => {
            // logic to move to the next page
            setTransactionOffset(transactionOffset + 10)
          }}
          onPrevPage={() => {
            // logic to move to the previous page
            setTransactionOffset(transactionOffset - 10)
          }}
        />
      </Card>
      <Modal isOpen={addFundsModalIsOpen} title="Add funds" onClose={() => setAddFundsModalIsOpen(false)}>
        <div className="mx-5 my-2">
            <span className="text-grey dark:text-gray-300 typo-p-small">
                Add funds to you account, will be replaced with Stripe payment integration.
            </span>
            <CurrencyInput
              id="input-example"
              name="addFunds"
              placeholder="Please enter a dollar amount"
              value={addFundsAmount}
              defaultValue={0}
              decimalsLimit={2}
              prefix="$"
              className="h-14 bg-gray-50 typo-p-large border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onValueChange={(value, _name) => {
                setAddFundsAmount(value)
              }}
            />
            <div className="flex justify-end mt-2">
              {isLoading ?
                <div className="my-3"><Spinner /></div> 
              :
                <button type="button" onClick={() => onAddFundsSubmit()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
              }
            </div>
        </div>
      </Modal>
    </div>
  )
}