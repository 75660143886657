import React, { createContext, useEffect, useState, useRef, useContext } from "react"
import { sessionQuery } from "../graphql/queries"
import { SessionQuery } from "../graphql/gql/graphql";
import { jwtDecode } from "jwt-decode";
import { useQuery } from 'urql';
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";

interface UseAuth {
  session: string | null,
  signOut: () => void,
  loading: boolean,
  userData?: SessionQuery["session"]
}

type Props = {
  children?: React.ReactNode
}

export const authContext = createContext<UseAuth>({} as UseAuth)
const useAuthProvider = (): UseAuth => {
  const [session, setSession] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true)
  const fragment = new URLSearchParams(location.hash.substring(1));
  const access_token = fragment.get("access_token");
  if (access_token) {
    localStorage.setItem("session", access_token);
    window.location.hash = '';
  }

  const [{ data: sessionData, fetching: sessionDataFetching, error: sessionDataError }] = useQuery({
    query: sessionQuery,
    pause: !session,
  })

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("session");
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds
      if (decoded.exp && decoded.exp < currentTime) {
        toast.warn("Your session expired. Please log back in to continue.", {position: "top-center"})
        signOut();
      }
    }
  };

  const getSession = async () => {
    checkTokenExpiration();
    const token = localStorage.getItem("session");
    setLoading(false)
    setSession(token)
  };

  const signOut = () => {
    localStorage.removeItem("session");
    setSession(null);
  };


  useEffect(() => {
    getSession();
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 1000 * 5); // every 5 seconds
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (sessionDataError) {
      signOut();
    }
  }, [sessionDataError])

  useEffect(() => {
    setLoading(sessionDataFetching)
  }, [sessionDataFetching])

  return {
    session,
    signOut,
    loading,
    userData: sessionData?.session
  }
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const auth = useAuthProvider()
    // Check if loading
    if (auth.loading) {
      return <authContext.Provider value={auth}>(<div className='flex h-screen'><div className='m-auto'><Spinner /></div></div>)</authContext.Provider>
    }
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}


export const useAuth = () => {
  const auth  = useContext(authContext)
  if (!auth) {
      throw new Error('useAuth must be used within an AuthProvider')
  }
  return auth
}