import React, { FC } from 'react'
import IconProps from './Icon.interface'

const IconMoneyTag: FC<IconProps> = ({
  color = '#F95454',
  width = 15,
  height = 15,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 15 15"
    className={className}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M7.893 13.383l3.73-3.732.911 2.319L4.822 15 1.817 7.351l6.075 6.032zM7.095 0H1.25v5.908L7.89 12.5l5.86-5.86L7.095 0zm2.516 5.923c-.26-.26-.541-.386-.862-.386-.36 0-.767.15-1.357.502-.426.257-.698.365-.91.365-.225 0-.676-.255-.6-.76.032-.216.156-.437.357-.638.408-.407.965-.53 1.423-.067l.465-.465c-.298-.297-.685-.495-1.1-.495-.354 0-.704.142-1.04.423l-.391-.391-.437.436.409.408c-.356.516-.527 1.263.047 1.836.632.632 1.367.338 2.133-.145.375-.237.677-.352.922-.352a.68.68 0 01.674.787c-.033.24-.163.476-.388.7-.282.283-.565.425-.842.425-.262 0-.467-.12-.656-.288l-.462.461c.29.287.676.471 1.085.471.34 0 .679-.126 1.007-.373l.338.336.435-.435-.325-.326c.465-.56.723-1.38.075-2.03zM3.56 1.25a1.062 1.062 0 110 2.124 1.062 1.062 0 010-2.124z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconMoneyTag
