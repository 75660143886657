import React, { FC } from 'react'
import IconProps from './Icon.interface'

const VisaIcon: FC<IconProps> = ({
  width = 46,
  height = 28,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 46 28"
    className={className}
  >
    <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" fill="#E8E8E8" />
    <mask id="mask0_1891_33991" maskUnits="userSpaceOnUse" x="1" y="1" width="44" height="26">
      <path d="M3.5 1C2.83696 1 2.20107 1.26339 1.73223 1.73223C1.26339 2.20107 1 2.83696 1 3.5L1 24.5C1 25.163 1.26339 25.7989 1.73223 26.2678C2.20107 26.7366 2.83696 27 3.5 27H42.5C43.163 27 43.7989 26.7366 44.2678 26.2678C44.7366 25.7989 45 25.163 45 24.5V3.5C45 2.83696 44.7366 2.20107 44.2678 1.73223C43.7989 1.26339 43.163 1 42.5 1L3.5 1Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1891_33991)">
      <path d="M46.1667 -1H-1V29H46.1667V-1Z" fill="white" />
      <path d="M45.4166 24.0833H-0.166748V28.1667H45.4166V24.0833Z" fill="#FCB316" />
      <path d="M45.4166 -0.166656H-0.166748V3.91668H45.4166V-0.166656Z" fill="#10357F" />
      <path d="M18.0834 8.41667L13.3334 19.6667H10.2501L7.91675 10.6667C7.91675 10.25 7.58341 9.83334 7.25008 9.66667C6.33341 9.25001 5.33341 8.91667 4.41675 8.75L4.50008 8.41667H9.41675C10.0834 8.41667 10.6667 8.91667 10.7501 9.58334L12.0001 16.0833L15.0001 8.41667H18.0834ZM30.1667 16C30.1667 13 26.0834 12.8333 26.0834 11.5C26.0834 11.0833 26.5001 10.6667 27.3334 10.5833C28.3334 10.5 29.3334 10.6667 30.1667 11.0833L30.6667 8.66668C29.8334 8.33334 28.8334 8.16667 27.9167 8.16667C25.0001 8.16667 23.0001 9.66667 23.0001 11.9167C23.0001 13.5 24.4167 14.4167 25.5001 15C26.5834 15.5833 27.0001 15.9167 27.0001 16.4167C27.0001 17.1667 26.0834 17.5 25.2501 17.5C24.2501 17.5 23.1667 17.25 22.2501 16.75L21.7501 19.25C22.7501 19.6667 23.9167 19.8333 25.0001 19.8333C28.0834 19.8333 30.0834 18.3333 30.1667 16ZM37.7501 19.6667H40.4167L38.0834 8.41667H35.5834C35.0001 8.41667 34.5834 8.75001 34.3334 9.25001L30.0001 19.6667H33.0834L33.6667 18H37.4167L37.7501 19.6667ZM34.5001 15.6667L36.0001 11.4167L36.9167 15.6667H34.5001ZM22.2501 8.41667L19.8334 19.6667H16.9167L19.3334 8.41667H22.2501Z" fill="#10357F" />
    </g>
    <defs>
      <clipPath id="clip0_451_1975">
        <path fill="#fff" d="M0 0H46V28H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default VisaIcon
