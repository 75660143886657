import GoogleSignInButton from '../../components/GoogleSignInButton';
import React from 'react';
const SignIn: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-60 lg:py-0">
      <a href="#" className="flex items-center mb-6 typo-h1 font-semibold text-deepBlue dark:text-white">
        Jib Technologies    
      </a>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="typo-p-large font-bold leading-tight tracking-tight text-deepBlue dark:text-white">
            Sign in to your account
          </h1>
          <GoogleSignInButton />
        </div>
      </div>
    </div>
  );
}

export default SignIn;
