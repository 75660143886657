export enum BadgeVariants {
  ACTIVE = 'active',
  PENDING = 'pending',
  EXPIRING = 'expiring',
  PAUSE = 'pause',
  TERMINATED = 'terminated',
  INCOMPLETE = 'incomplete',
  CARD_LIMIT_REACHED = 'cardLimitReached',
  END_DATE_REACHED = 'endDateReached',
  
}
export default interface BadgeProps {
  label: string
  variant: BadgeVariants
}
