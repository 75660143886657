import {FC} from 'react'
import ShareOfferCardProps from './ShareOfferCard.interface'
import styles from './ShareOfferCard.module.scss'
import Button from '../Button/Button'
import {ButtonVariants} from '../Button/Button.interface'
import QrCode from '../QrCode/QrCode'
import { QrCodeVariants } from '../QrCode/QrCode.interface'
import Spinner from '../Spinner'

const ShareOfferCard: FC<ShareOfferCardProps> = ({ reactivateOfferCallback, shareLinkCallback, pauseCancelOfferCallback, qrHref, isLoading, offerState }) => (
  <div className={`${styles.root}`}>
    <div className={`${styles.leftContainer}`}>
      <div className={`${styles.titleWrapper}`}>
        <div className={`${styles.title}`}>
          Share your offer
        </div>
      </div>
      <div className={`${styles.bottomContainer}`}>
        <div className={`${styles.text}`}>
          Use this custom QR code or&nbsp;
          <button
            type="button"
            onClick={shareLinkCallback}
            className={`${styles.shareLink}`}
          >
          share link
          </button>
           &nbsp;to quicky share out your offer to gain traction quicky.
        </div>
        <div className={`${styles.buttonWrapper}`}>
          { (offerState === "PENDING" || offerState === "ACTIVE") &&           
            <Button
              label="Pause Offer"
              variant={ButtonVariants.PRIMARY_OUTLINE}
              onClick={pauseCancelOfferCallback}
          />}

          { offerState === "PAUSED" && (
            <>
            {!isLoading ? (<Button
              label="Reactivate Offer"
              variant={ButtonVariants.PRIMARY_OUTLINE}
              onClick={reactivateOfferCallback} 
            />) : <Spinner/>}
            </>

          )}
        </div>
      </div>
    </div>
    <div className={`${styles.rightContainer}`}>
      <QrCode href={qrHref} variant={QrCodeVariants.WHITE_BACKGROUND} />
    </div>
  </div>
)

export default ShareOfferCard
