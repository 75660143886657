import styles from './offerDetail.module.scss';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../utils/data';
import { getOfferQuery, listMerchantsQuery } from '../../graphql/queries';
import { useMemo, useState } from 'react';
import { useQuery, useMutation } from 'urql';
import Spinner from '../../components/Spinner';
import Badge from '../../components/Badge/Badge';
import getOfferDetailStatus from '../../utils/offerToBadge';
import Button from '../../components/Button/Button';
import { ButtonVariants } from '../../components/Button/Button.interface';
import { toast } from 'react-toastify';
import ShareOfferCard from '../../components/ShareOfferCard/ShareOfferCard';
import RedeemedCard from '../../components/RedeemedCard/RedeemedCard';
import OfferPermissionsCard from '../../components/OfferPermissionsCard/OfferPermissionsCard';
import OfferInfoCard from '../../components/OfferInfoCard';
import OfferForm from '../../components/OfferForm';
import { updateOfferMutation } from '../../graphql/mutations';
import Modal from '../../components/Modal';
import OfferPermissionsForm from '../../components/OfferPermissionsForm';

type OfferDetailParams = {
  id: string
}

export default function OfferDetail() {
  const { id } = useParams<keyof OfferDetailParams>() as OfferDetailParams
  const [updateOfferModalIsOpen, setUpdateOfferModalIsOpen] = useState(false)
  const [pauseModalIsOpen, setPauseModalIsOpen] = useState(false)
  const [reactivateModalIsOpen, setReactivateModalIsOpen] = useState(false)
  const [offerPermissionsModalIsOpen, setOfferPermissionsModalIsOpen] = useState(false)
  const [{ data, fetching, error }] = useQuery({
    query: getOfferQuery,
    variables: {
      id
    },
    requestPolicy: 'network-only'
  })
  const [{ data: merchantsData, fetching: merchantsFetching, error: merchantsError }] = useQuery({
    query: listMerchantsQuery,
    variables: {
      offset: 0,
      limit: 100,
    },
    requestPolicy: 'network-only'
  })

  const [, updateOffer] = useMutation(updateOfferMutation)

  const pauseReactivateOffer = async () => {
    if (data?.getOffer.__typename === 'BaseError') {
      console.error('Base Error Occurred on offer fetch')
    } else {
      updateOffer({
        id,
        isActive: !data?.getOffer.data.isActive
      }).then((result) => {
        if (result.data?.updateOffer.__typename === 'BaseError') {
          toast.error(result.data.updateOffer.message)
        } else {
          toast.success('Offer updated successfully')
        }
      }).finally(() => {
        setPauseModalIsOpen(false)
        setReactivateModalIsOpen(false)
      })
    }
    
  }

  async function copyLinkToClipboard(): Promise<void> {
    try {
        if (data?.getOffer.__typename !== 'BaseError') {
          await navigator.clipboard.writeText(data?.getOffer.data.dedicatedOfferLinkUrl ?? "");
          toast.success('Copied to clipboard')
        }
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
  }

  const merchantsString = useMemo(() => {
    if (merchantsFetching) return 'Loading...'
    if (data?.getOffer.__typename === 'QueryGetOfferSuccess') {
      if (!data.getOffer.data.authRules?.allowedMerchants || data.getOffer.data.authRules?.allowedMerchants.length === 0) return 'N/A **Your Offer can be redeemed at any merchant**'
      return data.getOffer.data.authRules.allowedMerchants.map((merchant) => merchant.descriptor).join(', ')
    }
    return ''
  }, [data])


  return (
    <div className={styles.root}>
      {fetching ? <div className='flex my-40 mx-auto w-fit'><Spinner /></div> : <div>
        {data?.getOffer.__typename === "QueryGetOfferSuccess" && <div>
          <div className='flex justify-between pb-10'>
              <div>
                <p className='typo-h2 dark:text-white mb-2'>{data.getOffer.data.name}</p>
                <div className='flex justify-between items-center'>
                  <p className='typo-p-medium dark:text-white'>Offer Begins {formatDate(data.getOffer.data.startDate)}</p>
                  <Badge label={data.getOffer.data.status} variant={getOfferDetailStatus(data.getOffer.data.status)}/>
                </div>
              </div>
              <Button 
                label='Edit'
                variant={ButtonVariants.PRIMARY}
                onClick={() => setUpdateOfferModalIsOpen(true)}
              />

          </div>
          <div className='grid grid-cols-2 gap-4'>
              <RedeemedCard 
                infoCallBack={() => toast.warn('This feature is not yet implemented')}
                optIns={data.getOffer.data.optIns}
                perCard={data.getOffer.data.cardLoadAmount}
                redemptions={data.getOffer.data.redemptions}
              />
            <OfferPermissionsCard 
              allowedMerchants={merchantsString}
              descriptor='All'
              editDetailsCallBack={() => setOfferPermissionsModalIsOpen(true)}
            />

            <div className='col-span-2'>
              <OfferInfoCard 
                customMessageRequest={data.getOffer.data.customMessageRequest}
                customMessageResponse={data.getOffer.data.customMessageResponse}
                descriptionText={data.getOffer.data.description ?? undefined}
                endDate={data.getOffer.data.endDate}
                multiMediaImageUrl={data.getOffer.data.multiMediaImageUrl ?? undefined}
                startDate={data.getOffer.data.startDate}
                maxOptIns={data.getOffer.data.maxOptIns}
              />
            </div>
            
            <div className='col-span-2'>
              <ShareOfferCard 
                isLoading={fetching}
                offerState={data.getOffer.data.status}
                pauseCancelOfferCallback={() => setPauseModalIsOpen(true)}
                reactivateOfferCallback={() => setReactivateModalIsOpen(true)}
                shareLinkCallback={() => copyLinkToClipboard()}
                qrHref={data.getOffer.data.dedicatedOfferLinkUrl}
              />
            </div>
          </div>
          <OfferForm 
            createOfferModalIsOpen={updateOfferModalIsOpen}
            setCreateOfferModalIsOpen={setUpdateOfferModalIsOpen}
            offerData={data.getOffer as any}
          />
        </div>}
      </div>}
      <Modal 
        title='Pause Offer'
        isOpen={pauseModalIsOpen}
        onClose={() => setPauseModalIsOpen(false)}
      >
        <div className="mx-5 my-2">
          <span className="text-gray-700 dark:text-gray-300 typo-p-medium">
            Are you sure you want to pause this offer? This will prevent your customers from being able to opt-in to this offer. This will immediately take effect.
          </span>
          <div className="flex justify-end mt-2">
            <button type="button" onClick={pauseReactivateOffer} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg typo-p-small px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Pause Offer</button>
          </div>
        </div>
      </Modal>
      <Modal 
        title='Reactivate Offer'
        isOpen={reactivateModalIsOpen}
        onClose={() => setReactivateModalIsOpen(false)}
      >
        <div className="mx-5 my-2">
          <span className="text-gray-700 dark:text-gray-300 typo-p-medium">
            Are you sure you want to reactivate this offer? This will allow your customers to opt-in to this offer. This will immediately take effect.
          </span>
          <div className="flex justify-end mt-2">
            <button type="button" onClick={pauseReactivateOffer} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg typo-p-small px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Reactivate Offer</button>
          </div>
        </div>
      </Modal>
      <Modal 
        title='Edit Offer Permissions'
        isOpen={offerPermissionsModalIsOpen}
        onClose={() => setOfferPermissionsModalIsOpen(false)}
      >
        {offerPermissionsModalIsOpen &&
          <OfferPermissionsForm 
            merchants={merchantsData?.merchants.__typename === 'QueryMerchantsSuccess' ? merchantsData.merchants.data.merchants : []}
            selectedMerchants={(data?.getOffer.__typename === 'QueryGetOfferSuccess' && data.getOffer.data.authRules?.allowedMerchants) ? data.getOffer.data.authRules.allowedMerchants : []}
            offerId={id}
            finallyCallback={() => setOfferPermissionsModalIsOpen(false)}
          />
        }
      </Modal>
    </div>
  )
}