import { ReactNode } from "react"

export enum LinkSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

export default interface LinkProps {
  label: ReactNode
  size?: LinkSize
  className?: string
  onClick?: () => void
}
