import { useMemo } from "react";
import styles from './Modal.module.scss'

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean,
  title: string,
  onClose: () => void; // add this line
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, title, onClose }) => {

    const hidden = useMemo(() => {
        return !isOpen ? "hidden" : ""
    }, [isOpen])

    return (
        <>
        {isOpen && (
        // Backdrop
        <div
          className="fixed inset-0 bg-slate-500/50 dark:bg-slate-700/50 z-40"
          onClick={onClose} // Optional: close modal on backdrop click
        ></div>
        )}
        <div
            id="defaultModal"
            tabIndex={-1}
            aria-hidden="false"
            className={`${styles.root} ${hidden}`}
        >
            <div className={styles.modal}>
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-start justify-between p-6 border-b rounded-t dark:border-gray-600">
                        <h3 className="typo-h3 font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                            <svg className="w-3 h-3" aria-hidden="false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-1 space-y-0">
                        {children}
                    </div>
                </div>
            </div>
        </div>
        </>
  )
}
export default Modal