import React, { FC } from 'react'
import IconProps from './Icon.interface'

const StarIcon: FC <IconProps> = ({
  color = '#26346A',
  width = 15,
  height = 15,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 15 15"
    className={className}
    >
      <path
        fill={color}
        d="M7.5 0l1.77 5.41H15l-4.636 3.346 1.771 5.41L7.5 10.823l-4.636 3.345 1.771-5.411L0 5.41h5.73L7.5 0z"
      />
    </svg>
)

export default StarIcon
