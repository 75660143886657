import { FC } from 'react'
import IconProps from './Icon.interface'

const Pending: FC<IconProps> = ({
  color = '#FFAA47',
  width = 16,
  height = 16,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.30582 9.13595e-05C6.24456 -0.000726964 6.18068 0.00474557 6.11778
      0.0192708C5.20924 0.227125 4.36041 0.592775 3.59587 1.08213C3.16142 1.36036
      3.10462 1.98215 3.46788 2.34957L3.46946 2.35117C3.72997 2.61467 4.13848 2.67711
      4.44915 2.47744C5.06968 2.07809 5.76153 1.7829 6.50017 1.61596C6.85858 1.53495
      7.10063 1.19551 7.10063 0.824808C7.10063 0.367979 6.73461 0.00581962 6.30582
      9.13595e-05ZM9.51509 9.13595e-05C9.08614 0.00636782 8.7187 0.367979 8.7187
      0.824808V0.826406C8.7187 1.2012 8.96857 1.53491 9.33021 1.61756C12.2177 2.27385 14.3819
      4.88416 14.3819 7.99952C14.3819 11.1149 12.2177 13.7252 9.33021 14.3815C8.96857 14.4641
      8.7187 14.7978 8.7187 15.1726V15.1742C8.7187 15.6963 9.19833 16.096 9.70155 15.9798C13.3026
      15.1533 16 11.889 16 7.99952C16 4.11003 13.3026 0.846595 9.70155 0.0192708C9.63864
      0.00484786 9.57637 -0.000805277 9.51509 9.13595e-05ZM1.66335 3.26859C1.42964 3.29171
      1.20834 3.41647 1.0708 3.63619C0.586998 4.40951 0.226308 5.26808 0.0200041 6.18706C-0.0940697
      6.69606 0.300233 7.1812 0.816397 7.1812C1.18289 7.1812 1.51847 6.93554 1.59857 6.57385C1.7628
      5.8259 2.05546 5.12692 2.45027 4.49927C2.64767 4.18503 2.58594 3.77183 2.32543 3.50833L2.32385
      3.50673C2.14223 3.32302 1.89706 3.24547 1.66335 3.26859ZM11.13 5.53656C10.9198 5.54289 10.7203
      5.63175 10.5738 5.78429L7.10063 9.29733L6.05457 8.23926C5.98003 8.16072 5.89073 8.09802
      5.79193 8.05483C5.69312 8.01163 5.58679 7.98881 5.47915 7.9877C5.37152 7.9866 5.26475
      8.00722 5.16509 8.04838C5.06544 8.08953 4.9749 8.15038 4.89879 8.22737C4.82268 8.30435
      4.76252 8.39593 4.72183 8.49673C4.68115 8.59753 4.66075 8.70552 4.66185 8.81439C4.66294
      8.92326 4.6855 9.03082 4.72821 9.13076C4.77091 9.2307 4.8329 9.32102 4.91055 9.39642L6.52862
      11.0331C6.68035 11.1865 6.8861 11.2727 7.10063 11.2727C7.31516 11.2727 7.52091 11.1865
      7.67264 11.0331L11.7178 6.94145C11.8347 6.82651 11.9146 6.67852 11.9469 6.51689C11.9792
      6.35525 11.9625 6.18751 11.899 6.03564C11.8354 5.88377 11.728 5.75486 11.5908 5.6658C11.4536
      5.57675 11.293 5.5317 11.13 5.53656ZM0.816397 8.81784C0.300233 8.81784 -0.0948787 9.30298
      0.0200041 9.81198C0.226308 10.731 0.586189 11.5895 1.0708 12.3628C1.34506 12.8023 1.9606
      12.8597 2.32385 12.4923C2.58517 12.228 2.64767 11.814 2.45027 11.4998C2.05546 10.8721 1.76361
      10.1723 1.59857 9.42519C1.51847 9.06267 1.18289 8.81784 0.816397 8.81784ZM3.94192 13.4113C3.76818
      13.4348 3.59971 13.5161 3.46946 13.6479L3.46788 13.6495C3.10462 14.0169 3.16142 14.6387 3.59587
      14.9169C4.36041 15.4063 5.20924 15.7711 6.11778 15.9798C6.621 16.0951 7.10063 15.6963 7.10063
      15.1742C7.10063 14.8035 6.85777 14.4641 6.50017 14.3831C5.76153 14.2161 5.07045 13.9209 4.45073
      13.5216C4.29539 13.4218 4.11566 13.3878 3.94192 13.4113Z"
      fill={color}
    />
  </svg>
)

export default Pending
