import React, { FC } from 'react'
import IconProps from './Icon.interface'

const JCBIcon: FC<IconProps> = ({
  width = 46,
  height = 28,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 46 28"
    className={className}
  >
    <g clipPath="url(#clip0_1891_34544)">
      <mask id="path-1-inside-1_1891_34544" fill="white">
        <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" />
      </mask>
      <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" fill="white" />
      <path d="M3.5 28V27V28ZM0 24.5H-1H0ZM3.5 0V-1V0ZM46 24.5H45H46ZM42.5 27H3.5V29H42.5V27ZM3.5 27C2.83696 27 2.20107 26.7366 1.73223 26.2678L0.318019 27.682C1.16193 28.5259 2.30653 29 3.5 29V27ZM1.73223 26.2678C1.26339 25.7989 1 25.163 1 24.5H-1C-1 25.6935 -0.525894 26.8381 0.318019 27.682L1.73223 26.2678ZM1 24.5V3.5H-1V24.5H1ZM1 3.5C1 2.83696 1.26339 2.20107 1.73223 1.73223L0.318019 0.318019C-0.525894 1.16193 -1 2.30653 -1 3.5H1ZM1.73223 1.73223C2.20107 1.26339 2.83696 1 3.5 1V-1C2.30653 -1 1.16193 -0.525894 0.318019 0.318019L1.73223 1.73223ZM3.5 1H42.5V-1H3.5V1ZM42.5 1C43.163 1 43.7989 1.26339 44.2678 1.73223L45.682 0.318019C44.8381 -0.525894 43.6935 -1 42.5 -1V1ZM44.2678 1.73223C44.7366 2.20107 45 2.83696 45 3.5H47C47 2.30652 46.5259 1.16193 45.682 0.318019L44.2678 1.73223ZM45 3.5V24.5H47V3.5H45ZM45 24.5C45 25.163 44.7366 25.7989 44.2678 26.2678L45.682 27.682C46.5259 26.8381 47 25.6935 47 24.5H45ZM44.2678 26.2678C43.7989 26.7366 43.163 27 42.5 27V29C43.6935 29 44.8381 28.5259 45.682 27.682L44.2678 26.2678Z" fill="#E8E8E8" mask="url(#path-1-inside-1_1891_34544)" />
      <path d="M29.839 14.4453C30.6247 14.4626 31.4148 14.4101 32.1973 14.4725C32.9898 14.6223 33.1808 15.8375 32.4767 16.2355C31.9964 16.4977 31.4257 16.3332 30.9049 16.3794H29.839V14.4453ZM32.6516 12.256C32.8262 12.8801 32.2322 13.4405 31.6388 13.3546H29.839C29.8516 12.766 29.8143 12.1272 29.8573 11.5696C30.5783 11.5902 31.3059 11.5277 32.0228 11.6023C32.3308 11.6807 32.5885 11.9372 32.6516 12.256ZM36.9831 3.00065C37.0166 4.19255 36.988 5.44744 36.9976 6.66351C36.9954 11.6076 37.0023 16.5516 36.9938 21.496C36.9622 23.3489 35.341 24.9589 33.5244 24.9959C31.7061 25.0034 29.8875 24.9971 28.0689 24.9991V17.5247C30.0504 17.5143 32.0328 17.5457 34.0134 17.509C34.9322 17.4506 35.9387 16.8365 35.9814 15.8122C36.0896 14.7836 35.1322 14.0721 34.2232 13.9597C33.8738 13.9505 33.884 13.8566 34.2232 13.8156C35.0902 13.6258 35.771 12.7168 35.5159 11.8066C35.2985 10.8491 34.2539 10.4786 33.3849 10.4804C31.6132 10.4682 29.8411 10.4787 28.0693 10.4752C28.0807 9.07979 28.0455 7.6829 28.0883 6.28853C28.2287 4.46905 29.8907 2.96858 31.6817 3.00113C33.4491 3.00086 35.2162 3.00086 36.9831 3.00079V3.00065Z" fill="url(#paint0_linear_1891_34544)" />
      <path d="M8.04713 6.47701C8.09238 4.62702 9.72044 3.03018 11.5347 3.00314C13.3463 2.99749 15.1582 3.00232 16.9698 3.00069C16.9648 9.19036 16.9796 15.3807 16.9623 21.57C16.8925 23.3975 15.2822 24.9639 13.4877 24.9963C11.6727 25.003 9.85746 24.9973 8.04235 24.9991V17.2726C9.80542 17.6946 11.6543 17.8741 13.4529 17.5943C14.5281 17.4191 15.7044 16.8842 16.0684 15.7544C16.3362 14.7879 16.1854 13.7708 16.2253 12.7788V10.4752H13.1126C13.0986 11.9988 13.1412 13.525 13.0901 15.0467C13.0062 15.9821 12.0917 16.5763 11.2208 16.5446C10.1408 16.5562 8.00053 15.7517 8.00053 15.7517C7.99515 12.8972 8.03186 9.32234 8.04713 6.47728V6.47701Z" fill="url(#paint1_linear_1891_34544)" />
      <path d="M18.1305 11.4044C17.9667 11.4396 18.0976 10.8391 18.0557 10.6113C18.0669 9.17088 18.0324 7.7289 18.0747 6.28952C18.2147 4.46234 19.8894 2.95812 21.6877 3.00089H26.9835C26.9786 9.19056 26.9933 15.3809 26.9761 21.5702C26.9062 23.3977 25.2958 24.9641 23.5013 24.9965C21.6862 25.0034 19.8709 24.9976 18.0556 24.9994V16.534C19.2953 17.5643 20.9803 17.7247 22.5248 17.7275C23.6891 17.7271 24.8467 17.5453 25.9774 17.2733V15.7224C24.7031 16.3657 23.2052 16.7743 21.7926 16.4047C20.8072 16.1563 20.0919 15.1916 20.1079 14.1616C19.9937 13.0904 20.6136 11.9594 21.6528 11.641C22.9431 11.2318 24.3493 11.5447 25.5588 12.0767C25.8179 12.2141 26.0809 12.3846 25.9773 11.9459V10.7269C23.9545 10.2394 21.8019 10.06 19.7697 10.5903C19.1815 10.7584 18.6084 11.0133 18.1305 11.4046V11.4044Z" fill="url(#paint2_linear_1891_34544)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1891_34544" x1="28.008" y1="13.5295" x2="36.9413" y2="13.5295" gradientUnits="userSpaceOnUse">
        <stop stopColor="#007B40" />
        <stop offset="1" stopColor="#55B330" />
      </linearGradient>
      <linearGradient id="paint1_linear_1891_34544" x1="7.85241" y1="14.2105" x2="16.8234" y2="14.2105" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1D2970" />
        <stop offset="1" stopColor="#006DBA" />
      </linearGradient>
      <linearGradient id="paint2_linear_1891_34544" x1="18.0425" y1="13.7429" x2="26.9819" y2="13.7429" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6E2B2F" />
        <stop offset="1" stopColor="#E30138" />
      </linearGradient>
      <clipPath id="clip0_1891_34544">
        <rect width="46" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default JCBIcon
