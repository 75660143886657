import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const PlusIcon: FunctionComponent<IconProps> = ({
  color = '#00AAE7',
  width = 22,
  height = 22,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <line
      x1="11.1201"
      y1="1"
      x2="11.1201"
      y2="21"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="1"
      y1="10.88"
      x2="21"
      y2="10.88"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PlusIcon
