import { FC } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import SingleSelectProps from './SingleSelect.interface'
import './SingleSelect.styles.scss'

const animatedComponents = makeAnimated()

const SingleSelect: FC<SingleSelectProps> = (props: SingleSelectProps) => {
  const {
    defaultValue,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    options,
    placeholder,
    onChange,
    onBLur,
    onInputChange,
    label,
    isRequired,
    hasError,
    errorMessage = '',
    detail,
    customClass,
    innerRef,
    name,
    value
  } = props

  return (
    <div className={`single-select ${customClass}`}>
      <span className={hasError ? 'title-error' : 'title'}>
        {label} {isRequired && '*'}
      </span>
      <Select
        name={name}
        ref={innerRef}
        components={animatedComponents}
        className={`${
          hasError ? 'error-single-select' : 'single-select'
        }`}
        classNamePrefix="select"
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBLur}
        onInputChange={onInputChange}
        value={value}
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: 'none',
          }),
        }}
      />
      {hasError && <p className="error">{errorMessage}</p>}
      {detail && <p className="detail">{detail}</p>}
    </div>
  )
}

export default SingleSelect
