import { BsFillTrashFill } from "react-icons/bs";
import { useState, useRef, useMemo, useEffect } from "react";
import Modal from "../../components/Modal"
import { AiOutlinePlus, AiOutlineCopy } from "react-icons/ai";
import { useQuery, useMutation } from 'urql';
import { listApiKeysQuery } from "../../graphql/queries"
import { createApiKeyMutation, deleteApiKeyMutation } from "../../graphql/mutations"
import { formatDate } from "../../utils/data";
import { toast } from 'react-toastify';
import styles from "./apiKeys.module.scss"
import Spinner from '../../components/Spinner';

export default function ApiKeys() {
  /* State */
  const [createApiKeyModelIsOpen, setCreateApiKeyModelIsOpen] = useState<boolean>(false)
  const [deleteApiKeyModelIsOpen, setDeleteApiKeyModelIsOpen] = useState<boolean>(false)
  const [createSuccessApiKeyModalIsOpen, setCreateSuccessApiKeyModalIsOpen] = useState<boolean>(false)
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [apiKeyIdSelectedForDelete, setApiKeyIdSelectedForDelete] = useState<string | undefined>(undefined)
  const [name, setName] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  /* Query */
  const [{ data, fetching, error }] = useQuery({
    query: listApiKeysQuery
  })

  /* Mutations */
  const [deleteApiKeyResult, deleteApiKey] = useMutation(deleteApiKeyMutation)
  const [createApiKeyResult, createApiKey] = useMutation(createApiKeyMutation)

  /* Application Error Handling */
  useEffect(() => {
    if (data?.apiKeys.__typename === "BaseError") {
      toast.error(data.apiKeys.message, {position: "top-center"})
      console.error(data.apiKeys.message)
    }
  }, [data])

  /* GraphQl Error Handling */
  useEffect(() => {
    if (error) {
      toast.error(error.message, {position: "top-center"})
      console.error(error.message)
    }
  }, [error])

  /* Handlers */
  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const handleCopyClick = () => {
    if (inputRef.current) {
      navigator.clipboard.writeText(inputRef.current.value)
      .then(() => {
        toast.success("Api Key Copied to Clip", {
          position: "bottom-center"
        })
      })
      .catch(_err => {
        toast.error("Unable to copy", {
          position: "bottom-center"
        })
      });
    }
  };

  /* Create Api Key */
  const create = async () => {
    setIsCreating(true)
    createApiKey({name}).then((result) => {
      setCreateApiKeyModelIsOpen(false)
      if (result.data?.createApiKey.__typename === "BaseError") {
        toast.error(result.data?.createApiKey.message, {position: "top-center"})
        console.error(result.data?.createApiKey.message)
      } else {
        setCreateSuccessApiKeyModalIsOpen(true)
      }
    }).catch((error) => {
      toast.error(error.message, {
        position: "top-center"
      })
    }).finally(() => {
      setIsCreating(false)
      setCreateApiKeyModelIsOpen(false)
      setName("")
    })
  }

  const key = useMemo(() => {
    if (createApiKeyResult.data?.createApiKey.__typename === "MutationCreateApiKeySuccess") {
      return createApiKeyResult.data?.createApiKey.data.key
    }
    return ""
  }, [createApiKeyResult])

  /* Delete Api Key */
  const handleDeleteApiKey = async () => {
    if (!apiKeyIdSelectedForDelete) {
      console.error("Api key not selected")
      return
    }
    deleteApiKey({id: apiKeyIdSelectedForDelete})
      .then((result) => {
        setDeleteApiKeyModelIsOpen(false)
        if (result.data?.deleteApiKey.__typename === "BaseError") {
          toast.error(result.data?.deleteApiKey.message, {position: "top-center"})
          console.error(result.data?.deleteApiKey.message)
        }
      })
      .catch((error) => {
        toast.error(error.message, {position: "top-center"})
    }).finally(() => {
      setDeleteApiKeyModelIsOpen(false)
    })
  } 

  return (
    <div className={styles.root}>
      <h1 className="typo-h2 text-black dark:text-slate-100 pb-5">Api Keys</h1>
        <p className="dark:text-gray-300 pb-5 typo-p-small">
          Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them.
        </p>
        <p className="dark:text-gray-300 pb-5 typo-p-small">
        Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, Jib Technologies may also automatically disable any API key that we've found has leaked publicly.
        </p>
        <table className="w-full typo-p-small text-left text-gray-500 dark:text-gray-400 mb-5">
          <thead className="typo-p-small text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="typo-p-small">
              <th scope="col" className="px-6 py-3">
                name
              </th>
              <th scope="col" className="px-6 py-3">
                key
              </th>
              <th scope="col" className="px-6 py-3">
                Created
              </th>
              <th scope="col" className="px-6 py-3">
              </th>
            </tr>
          </thead>
          <tbody>
            {fetching ? (
              <tr>
                <td colSpan={4}>
                  <div className='flex justify-center my-10'><Spinner /></div>
                </td>
              </tr>
            ) : (
              <>
                {data?.apiKeys.__typename === "BaseError" || data?.apiKeys.data.length === 0 ? (
                  <tr>
                    <td colSpan={4}>
                      <h1 className='my-10 text-center h-8 typo-p-small'>No api keys</h1>
                    </td>
                  </tr>
                ) : (
                  data?.apiKeys.data.map((row, rowIndex) => (
                    <tr 
                        key={rowIndex} 
                        className="bg-white dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4">{row.name}</td>
                      <td className="px-6 py-4">{`jt_${import.meta.env.VITE_STAGE}_*****`}</td>
                      <td className="px-6 py-4">{formatDate(row.timeCreated)}</td>
                      <td className="px-6 py-4 text-right">
                      <button onClick={() => {
                        setDeleteApiKeyModelIsOpen(true)
                        setApiKeyIdSelectedForDelete(row.id)
                      }} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                        <BsFillTrashFill />
                      </button>
                      </td>
                    </tr>
                ))
                )}
              </>
            )}
          </tbody>
        </table>
        <button type="button" onClick={() => setCreateApiKeyModelIsOpen(true)} className="py-2 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center">
          <AiOutlinePlus />
          <span className="ml-2">Create Api Key</span>
        </button>

        <Modal isOpen={createApiKeyModelIsOpen} title="Create Api key" onClose={()=>setCreateApiKeyModelIsOpen(false)}>
          <div className="mb-3 mx-5">
            <label className="block my-2 typo-p-small font-medium text-gray-900 dark:text-white">Name (Optional)</label>
            <input id="name" value={name} onChange={(e) => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 typo-p-small rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="My Test key" />
            <div className="flex justify-end">
            {isCreating ? 
              <div className="my-3"><Spinner /></div> 
            : 
              <button type="button" onClick={create} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center">
                <AiOutlinePlus />
                <span className="ml-2">Create Api Key</span>
              </button>
            }
            </div>
          </div> 
        </Modal>
        <Modal isOpen={deleteApiKeyModelIsOpen} title="Remove Api Key" onClose={()=>setDeleteApiKeyModelIsOpen(false)}>
          <div className="mx-5 my-2">
            <span className="text-gray-700 dark:text-gray-300 typo-p-small">
            This API key will immediately be disabled. API requests made using this key will be rejected, which could cause any systems still depending on it to break. Once revoked, you'll no longer be able to view or modify this API key.
            </span>
            <div className="flex justify-end mt-2">
              <button type="button" onClick={handleDeleteApiKey} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg typo-p-small px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Remove key</button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={createSuccessApiKeyModalIsOpen} title="Your Api Key" onClose={() => setCreateSuccessApiKeyModalIsOpen(false)}>
          <div className="mx-5 my-2">
            <span className="text-gray-700 dark:text-gray-300 typo-p-small">
            Please save this secret key somewhere safe and accessible. For security reasons, <span className="font-bold">you won't be able to view it again</span> through your Jib Technologies account. If you lose this secret key, you'll need to generate a new one.
            </span>
              <div className="mt-2 flex items-center">
                <input 
                  type="text"
                  id="name"
                  ref={inputRef}
                  onClick={handleInputClick}
                  className="p-3 bg-gray-50 border border-gray-300 text-gray-900 typo-p-small rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={key as string}
                />
                <button
                  type="button"
                  onClick={handleCopyClick}
                  className="ml-2 h-16 px-5 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center"
                >
                  <AiOutlineCopy/>
              </button>
              </div>
          </div>

        </Modal>
    </div>
  )
}