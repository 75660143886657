import { FC } from 'react'
import classNames from 'classnames'
import OfferCardProps, { OfferCardVariants } from './OfferCard.interface'
import styles from './OfferCard.module.scss'
import Badge from '../Badge/Badge'
import { BadgeVariants } from '../Badge/Badge.interface'

const offerCardToBadgeVariants = (offerCardVariant: OfferCardVariants) => {
  switch (offerCardVariant) {
    case OfferCardVariants.ACTIVE:
      return BadgeVariants.ACTIVE
    case OfferCardVariants.PENDING:
      return BadgeVariants.PENDING
    case OfferCardVariants.EXPIRING:
      return BadgeVariants.EXPIRING
    case OfferCardVariants.PAUSE:
      return BadgeVariants.PAUSE
    case OfferCardVariants.INCOMPLETE:
      return BadgeVariants.INCOMPLETE
    case OfferCardVariants.CARD_LIMIT_REACHED:
      return BadgeVariants.CARD_LIMIT_REACHED
    case OfferCardVariants.END_DATE_REACHED:
      return BadgeVariants.END_DATE_REACHED
    default:
      return BadgeVariants.PAUSE
  }
}

// color will be red if budget is below this value
const BUDGET_THRESHOLD = 15

const OfferCard: FC<OfferCardProps> = ({
  title,
  topRightLinkCallback,
  cardsCreated,
  budgetRemains,
  cardsRedeemedPerCent,
  cardsRedeemed,
  variant,
  addFundsCallBack,
}) => {

  const topRightLink = variant === OfferCardVariants.INCOMPLETE ? "Finish Setup" : "View offer"

  return (
    <div className={`${styles.root}`}>
    <div className={`${styles.contentWrapper}`}>
      <div className={`${styles.topContainer}`}>
        <div className={`${styles.titleWrapper}`}>
          <h3 className={`${styles.title}`}>
            {title}
          </h3>
        </div>
        <button
          type="button"
          onClick={topRightLinkCallback}
          className={`${styles.viewOffer}`}
        >
          {topRightLink}
        </button>
      </div>
      <div className={`${styles.bottomContainer}`}>
        <div className={`${styles.status}`}>
          <div className={`${styles.statusTitle}`}>status</div>
          <div className={`${styles.statusBadge}`}>
            {variant === OfferCardVariants.PAST
              ? <span className={`${styles.past}`}>Completed</span>
              : (
                <Badge
                  label={variant}
                  variant={offerCardToBadgeVariants(variant)}
                />
              )}
          </div>
          {variant === OfferCardVariants.EXPIRING && addFundsCallBack && (
            <button
              type="button"
              onClick={addFundsCallBack}
              className={`${styles.addFunds}`}
            >
              Add funds to offer
            </button>
          )}
        </div>
        <div className={`${styles.cardsCreated}`}>
          <div className={`${styles.cardsCreatedTitle}`}>Opt Ins</div>
          <div className={`${styles.cardsCreatedNumber}`}>{cardsCreated}</div>
          <div className={`${styles.cardsCreatedDescription}`}>
            <span
              className={classNames(
                styles.cardsCreatedDescriptionAccent, {
                  [styles.cardsCreatedDescriptionAccentDeepBlue]: variant === OfferCardVariants.PAST,
                  [styles.cardsCreatedDescriptionAccentErrorRed]: variant !== OfferCardVariants.PAST && typeof budgetRemains !== "string" && budgetRemains < BUDGET_THRESHOLD,
                },
              )}
            >
              {budgetRemains}%{` `}
            </span>
              of opt ins remains
          </div>
        </div>
        <div className={`${styles.cardsRedeemed}`}>
          <div className={`${styles.cardsRedeemedTitle}`}>redemptions</div>
          <div className={`${styles.cardsRedeemedNumber}`}>{cardsRedeemedPerCent}%</div>
          <div className={`${styles.cardsRedeemedDescription}`}>
            <span className={`${styles.cardsRedeemedDescriptionAccent}`}>{cardsRedeemed} </span>
            redemptions
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

  



export default OfferCard
