import { BadgeVariants } from '../components/Badge/Badge.interface'
import { OfferCardVariants } from '../components/OfferCard/OfferCard.interface'

const getOfferDetailStatus = (offerDetailsStatus: string) => {
    switch (offerDetailsStatus) {
      case "ACTIVE":
        return BadgeVariants.ACTIVE
      case "PENDING":
        return BadgeVariants.PENDING
      case "END_DATE_REACHED":
        return BadgeVariants.EXPIRING
      case "PAUSED":
        return  BadgeVariants.PAUSE
      default:
        return BadgeVariants.EXPIRING
    }
}

export const offerStateVariantMap = (variant: string) => {
  switch (variant) {
      case "ACTIVE":
          return OfferCardVariants.ACTIVE
      case "END_DATE_REACHED":
          return OfferCardVariants.END_DATE_REACHED
      case "PAUSED":
          return OfferCardVariants.PAUSE
      case "PENDING":
          return OfferCardVariants.PENDING
      default:
          throw new Error('Unknown offer state')

  }
}


export default getOfferDetailStatus