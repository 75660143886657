import { FC, useState } from 'react'
import RadioProps from './Radio.interface'
import styles from './Radio.module.scss'

const Radio: FC<RadioProps> = ({ label, handleChange}) => {
  const [value, setValue] = useState<boolean>(false)

  const handleValueChange = () => {
    handleChange(!value)
    setValue(!value)
  }
  return (
  <div className={`${styles.root}`}>
    <input type="checkbox" id={label} onChange={handleValueChange} className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
          
          <label className="ml-2 typo-p-small font-medium text-gray-900 dark:text-gray-100">
            {label}
          </label>
  </div>
  )
}

export default Radio
