import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const TriangleExclamationIcon: FunctionComponent<IconProps> = ({
  color = '#027FAC',
  width = 15,
  height = 14,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.07125 12.663L7.00725 0.288C7.107 0.11025 7.29525 0 7.5 0C7.704 0 7.893 0.11025 7.992 0.288L14.928 12.663C14.976 12.7493 15 12.8438 15 12.9383C15 13.227 14.7682 13.5 14.436 13.5H0.564C0.234 13.5 0 13.23 0 12.9383C0 12.8438 0.02325 12.7493 0.07125 12.663ZM1.5225 12.375H13.4767L7.49925 1.71075L1.5225 12.375ZM7.5015 7.50225C7.191 7.50225 6.939 7.75425 6.939 8.06475V10.6898C6.939 11.0003 7.191 11.2523 7.5015 11.2523C7.812 11.2523 8.064 11.0003 8.064 10.6898V8.06475C8.064 7.75425 7.812 7.50225 7.5015 7.50225ZM7.5 5.25225C7.914 5.25225 8.25 5.58825 8.25 6.00225C8.25 6.41625 7.914 6.75225 7.5 6.75225C7.086 6.75225 6.75 6.41625 6.75 6.00225C6.75 5.58825 7.086 5.25225 7.5 5.25225Z"
      fill={color}
    />
  </svg>
)

export default TriangleExclamationIcon
