import Card from "../../components/card";
import Select from 'react-select'
import { useEffect, useState } from "react";
import CurrencyInput from 'react-currency-input-field';
import Input from "../../components/Input";
import { simulateAuthorizationMutation, simulateVoidMutation, simulateClearingMutation } from "../../graphql/mutations"
import { useMutation } from 'urql';
import Spinner from '../../components/Spinner';
import Alert from "../../components/Alert";
import { toast } from "react-toastify";

interface IClearing {
  transactionToken: string
}
interface IVoid {
  transactionToken: string
}
interface IAuthorization {
  amount: number
  descriptor: string
  pan: string
  merchant_acceptor_id: string
}

export default function Simulate() {
  const options = [
    {
      value: "AUTHORIZATION",
      label: "Authorization"
    },
    {
      value: "CLEARING",
      label: "Clearing"
    },
    {
      value: "VOID",
      label: "Void"
    }
  ] as { value: string, label: string }[]
  const [option, setOption] = useState<{ value: string, label: string }>(options[0])
  const [authorization, setAuthorization] = useState<IAuthorization>({
    amount: 0,
    descriptor: "",
    pan: "",
    merchant_acceptor_id: ""
  })
  const [clearing, setClearing] = useState<IClearing>({
    transactionToken: ""
  })
  const [voiding, setVoiding] = useState<IVoid>({
    transactionToken: ""
  })
  const [isLoading, setIsLoading] = useState(false)
  const [transactionToken, setTransactionToken] = useState("")

  const [, simulateAuthorization] = useMutation(simulateAuthorizationMutation)
  const [, simulateVoid] = useMutation(simulateVoidMutation)
  const [, simulateClearing] = useMutation(simulateClearingMutation)

  const onSubmit = async () => {
    setIsLoading(true)
    console.log(authorization)
    switch (option.value) {
      case "AUTHORIZATION":
        simulateAuthorization(authorization).then((result) => {
          if (result.error) toast.error(result.error.message)
          setTransactionToken(result.data?.simulateAuthorization.token || "")
        }).catch((error) => {
          toast.error(error.message)
        }).finally(() => {setIsLoading(false)})
        break
      case "CLEARING":
        simulateClearing({
          token: clearing.transactionToken
        }).then((result) => {
          if (result.error) {
            toast.error(result.error.message)
            return
          }
          setClearing({
            transactionToken: ""
          })
          toast.success("Transaction cleared successfully")
        }).finally(() => {setIsLoading(false)})
        break
      case "VOID":
        simulateVoid({
          token: voiding.transactionToken
        }).then((result) => {
          if (result.error) {
            toast.error(result.error.message)
            return
          }
          setVoiding({
            transactionToken: ""
          })
          toast.success("Transaction voided successfully")
        }).finally(() => {setIsLoading(false)})
        break
    }
  }

  return (
    <div className="m-6">
      <Card className="mb-6 p-6">
      <h1 className="text-grey dark:text-gray-300 pb-5">Simulate</h1>
        <Select 
          options={options}
          onChange={(e) => setOption(e ?? options[0])}
          value={option}
        />
        {option.value === "AUTHORIZATION" && 
        <div className="gap-4 pt-10">
            <span className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">
              Authorization Amount*
            </span>
            <CurrencyInput
              id="input-example"
              name="addFunds"
              placeholder="Please enter a dollar amount"
              defaultValue={0}
              decimalsLimit={2}
              prefix="$"
              className="h-14 bg-gray-50 typo-p-large border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onValueChange={(value) => setAuthorization({...authorization, amount: Number(value)})}
            />
            <Input 
              label="Descriptor (Merchant/POS Name)*"
              name="descriptor"
              onChangeValue={(descriptor) => setAuthorization({...authorization, descriptor})}
              placeholder="Merchant/POS Name"
              value={authorization.descriptor}
            />
            <Input 
              label="Pan*"
              onChangeValue={(pan) => setAuthorization({...authorization, pan})}
              name="pan"
              placeholder="Pan"
              value={authorization.pan}
            />
            <Input 
              label="Merchant Acceptor Id (Id number of merchant / POS)*"
              onChangeValue={(merchant_acceptor_id) => setAuthorization({...authorization, merchant_acceptor_id})}
              placeholder="Id number of merchant / POS"
              name="merchant_acceptor_id"
              value={authorization.merchant_acceptor_id}
            />
        </div>}
        {(option.value === "CLEARING" || option.value === "VOID") && <div>
          <Input 
            label="Transaction Token*"
            onChangeValue={(transactionToken) => {
              setClearing({...clearing, transactionToken})
              setVoiding({...voiding, transactionToken})
            }}
            name="transactionToken"
            placeholder="Transaction Token ..."
            value={clearing.transactionToken || voiding.transactionToken}
          />
        </div>}
        <div className="flex justify-end mt-2">
              {isLoading ?
                <div className="my-3"><Spinner /></div> 
              :
                <button type="button" onClick={onSubmit} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
              }
            </div>
      </Card>
      {transactionToken && <Alert message={"Here is your transaction Token: " + transactionToken + " Use this token to simulate a void or clearing."}/>}
    </div>
  )
}