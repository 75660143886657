import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const CloseIcon: FunctionComponent<IconProps> = ({
  color = '#ffffff',
  width = 18,
  height = 18,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17 17L1 1M17 1L1 17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default CloseIcon
