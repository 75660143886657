import { Outlet } from "react-router-dom";
import NavBar from "../../components/NavBar"
import Footer from "../../components/Footer";

export default function Root() {
  return (
    <div className="flex flex-col h-screen">
      <NavBar />
      {/* Flex container for main content */}
      <div className="flex-grow">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
