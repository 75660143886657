import { FC } from 'react'
import LinkProps, { LinkSize } from './Link.interface'
import styles from './Link.module.scss'

// `Previous` LinkButton use DEFAULT font size (typo-cta-button)
// `Forgot Password` LinkButton use SMALL font size (typo-link-small)
const Link: FC<LinkProps> = ({ label, size = LinkSize.DEFAULT, onClick, className }) => (
  <span
    className={`${styles.root} ${styles[size]} ${className}`}
    onClick={onClick}
    aria-hidden="true"
  >
    {label}
  </span>
)

export default Link
