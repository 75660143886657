import { useQuery, useMutation } from "urql";
import { getPricingQuery, listMerchantCardsQuery } from "../../graphql/queries";
import { createMerchantCardMutation } from "../../graphql/mutations";
import styles from "./profile.module.scss"
import Pricing from "../../components/Pricing";
import Card from "../../components/card";
import Button from "../../components/Button/Button";
import { ButtonVariants } from "../../components/Button/Button.interface";
import Modal from "../../components/Modal";
import { useState, useEffect, useRef } from "react";
import Spinner from "../../components/Spinner";
import { AiOutlinePlus } from "react-icons/ai";
import { toUsd } from "../../utils/data";
import { toast } from "react-toastify";

export default function Profile() {
  const [createMerchantCardModalOpen, setCreateMerchantCardModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [{ data: pricing, fetching: pricingFetching, error: pricingError}] = useQuery({
    query: getPricingQuery,
  })
  const [{ data: merchantCards, fetching: merchantCardsFetching, error: merchantCardsError}] = useQuery({
    query: listMerchantCardsQuery,
    requestPolicy: "network-only"
  })

  const [, createMerchantCard] = useMutation(createMerchantCardMutation)

  const create = () => {
    setIsLoading(true)
    createMerchantCard({}).then((result) => {
      if (result.error) {
        console.error(result.error)
      } else if (result.data?.createMerchantCard.__typename === "BaseError") {
        toast.error(result.data.createMerchantCard.message)
      } else {
        toast.success("Merchant card created")
      }
    }).finally(() => {
      setIsLoading(false)
      setCreateMerchantCardModalOpen(false)
    })
  }

  useEffect(() => {
    // Function to handle the message event
    const handleMessage = (e: any) => {
      console.log("running 1")
      if (e.data.isCopied === true) {
        console.log("running")
        toast.success(e.data.copyElt + " copied!", {
          position: "top-center"
        });
      }
    };
  
    // Add the event listener
    window.addEventListener("message", handleMessage, false);
  
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  
  }, []); // Empty dependency array ensures this runs only onc

  return (
    <div className={styles.root}>
      <Pricing 
        fetching={pricingFetching}
        pricing={pricing?.pricing}
        className={styles.pricing}
      />
      <Card >
      <div className="m-10 ">
        <div className="flex justify-between pb-4">
          <p className="typo-h2 dark:text-white mb-2">Merchant Cards </p>
          <Button
            onClick={() => setCreateMerchantCardModalOpen(true)}
            label="Add Card"
            variant={ButtonVariants.PRIMARY}

          />
        </div>
        <p className="text-grey dark:text-gray-300 typo-p-small mb-5">
          Merchant cards are cards that you can use at the merchant point of sale to allow us to capture the merchant information.
        </p>
        {merchantCardsFetching ? <div className='flex my-40 mx-auto w-fit'><Spinner /></div> 
        :
          <>
          {merchantCards?.merchantCards.__typename === "QueryMerchantCardsSuccess" ? 
            merchantCards.merchantCards.data.map((card) => (
              <div key={card.id}>
                <iframe
                  title="merchant-card"
                  id="card-iframe"
                  allow="clipboard-write"
                  src={card.embedUrl!}
                  className={styles.iframe}
                />
              </div>
              ))
        :
          <div className="flex justify-center">
            <p className="typo-p-small text-grey dark:text-gray-300">No merchant cards found</p>
          </div>
        }
          </>
        }

      </div>
      </Card>
      <Modal 
        title="Create Merchant Card"
        isOpen={createMerchantCardModalOpen}
        onClose={() => setCreateMerchantCardModalOpen(false)}
      >
        <div className="m-10">
        <p className="dark:text-gray-300 pb-5 typo-p-small">Are you sure that you want to create a merchant card? Clicking submit will charge {toUsd(pricing?.pricing.cardCreation ?? 10000)} to you account.</p>
        <div className="flex justify-end">
            {isLoading ? 
              <div className="my-3"><Spinner /></div> 
            : 
              <button type="button" onClick={create} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center">
                <AiOutlinePlus />
                <span className="ml-2">Create Merchant Card</span>
              </button>
            }
            </div>
        </div>
      </Modal>
    </div>
  )
}