import { FC } from 'react'
import IconProps from './Icon.interface'

const CloseBanner: FC<IconProps> = ({
  color = '#26346A',
  width = 24,
  height = 24,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.2716 19.0297L12.452 14.2101L7.56444 19.0976L5.64111 17.1743L10.5286 12.2867L5.73162
      7.48973L7.47393 5.74742L12.2709 10.5444L17.1811 5.63428L19.1044 7.55761L14.1943 12.4678L19.0139 17.2874L17.2716 19.0297Z"
      fill={color}
    />
  </svg>
)

export default CloseBanner
