import VerificationInput from "react-verification-input";
import styles from './verifyPhoneNumberComplete.module.scss'
import { useState } from "react";
import Spinner from '../../components/Spinner'
import { useNavigate } from "react-router-dom";
import { addPhoneNumberToUserMutation } from "../../graphql/mutations"
import { useMutation } from 'urql';
import { toast } from "react-toastify";
export default function VerifyPhoneNumberComplete() {
  const navigate = useNavigate()
  const [value, setValue] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [, verifyPhoneNumberChallenge] = useMutation(addPhoneNumberToUserMutation)

  const onSubmit = async () => {
    setIsLoading(true)
    verifyPhoneNumberChallenge({ code: value }).then((result) => {
      if (result.data?.addPhoneNumberToUser.__typename === "MutationAddPhoneNumberToUserSuccess") {
        navigate('/')
      } else {
        toast.error(result.data?.addPhoneNumberToUser.message)
      }
    }).catch((error) => {
      toast.error(error.message)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <div className={styles.root}>
      <h1 className="text-grey dark:text-gray-300 pb-5">Verify Phone Number</h1>
      <p className="dark:text-gray-300 pb-5 typo-p-small">
        Enter the verification code sent to your phone number.
        </p>
      <div className="w-fit">
        <VerificationInput 
          value={value}
          onChange={(value) => setValue(value)}
        />
        <div className="flex justify-between mt-2 items-center">
          <span onClick={() => navigate("/verify-phone-number")} className="text-blue-400 dark:text-blue-500 typo-p-small hover:cursor-pointer">Didn't receive a code?</span>
          {isLoading ?
            <div className="my-3"><Spinner /></div> 
          :
            <button type="button" onClick={() => onSubmit()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
          }
        </div>
      </div>
    </div>
  )
}