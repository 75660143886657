import { FC, useEffect, useState, useMemo } from 'react'
import PricingProps from './Pricing.interface'
import styles from './Pricing.module.scss'
import { Pricing as GQlPricing } from "../../graphql/gql/graphql";
import { toUsd } from "../../utils/data"
import Card from "../../components/card";
import Modal from '../Modal';
import CurrencyInput from 'react-currency-input-field';
import Spinner from '../../components/Spinner';
import { useMutation } from 'urql';
import { updatePricingMutation, createCustomPricingPlanMutation } from '../../graphql/mutations';
import { toast } from "react-toastify";

export interface IPricingData {
  sms: string
  cardTransaction: string
  cardCreation: string
  sendFunds: string
  monthlyFee: string
  offerOptIn: string
}


const Pricing: FC<PricingProps> = ({ fetching, pricing, className, editable, userId}) => {

  const updatedPricingData = useMemo(() => ({
    cardCreation: String(pricing?.cardTransaction) || "0.00",
    cardTransaction: String(pricing?.cardTransaction) || "0.00",
    monthlyFee: String(pricing?.monthlyFee) || "0.00",
    sendFunds: String(pricing?.sendFunds) || "0.00",
    sms: String(pricing?.sms) || "0.00",
    offerOptIn: String(pricing?.offerOptIn) || "0.00"
  }), [pricing]);

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [updatePricingModalIsOpen, setUpdatePricingModalIsOpen] = useState<boolean>(false)
  const [pricingData, setPricingData] = useState<IPricingData>(updatedPricingData)

  const [updatePricingResult, updatePricing] = useMutation(updatePricingMutation)
  const [createCustomPricingPlanResult, createCustomPricingPlan] = useMutation(createCustomPricingPlanMutation)

  useEffect(() => {
    if(!updatePricingModalIsOpen) {
      setPricingData(updatedPricingData)
    }
  }, [updatedPricingData, updatePricingModalIsOpen])

  const onUpdatePricing = () => {
    setIsLoading(true)
    if (!pricing) {
      console.error("No pricing")
      setIsLoading(false)
      return
    }
    if (pricing.plan !== "CUSTOM") {
      handleCreate()
    } else {
      handleUpdate(pricing)
    }
  }

  const handleCreate = () => {
    if (!userId) {
      console.error("No user id")
      setIsLoading(false)
      return
    }
    createCustomPricingPlan({
      userId,
      cardCreation: Number(pricingData.cardCreation),
      cardTransaction: Number(pricingData.cardTransaction),
      monthlyFee: Number(pricingData.monthlyFee),
      sendFunds: Number(pricingData.sendFunds),
      sms: Number(pricingData.sms),
      offerOptIn: Number(pricingData.offerOptIn)

    }).then((result) => {
      if(result.error) {
        console.error(result.error)
        toast.error(result.error.message, {
          position: "top-center"
        })
        setIsLoading(false)
        return
      }
      toast.success("Pricing created successfully", {
        position: "top-center"
      })
    }).catch((error: any) => {
      console.error(error)
      toast.error(error.message, {
        position: "top-center"
      })
    }).finally(() => {
      setIsLoading(false)
      setUpdatePricingModalIsOpen(false)
    })
  }

  const handleUpdate = (pricing: GQlPricing) => {
    updatePricing({
      id: pricing?.id,
      cardCreation: Number(pricingData.cardCreation),
      cardTransaction: Number(pricingData.cardTransaction),
      monthlyFee: Number(pricingData.monthlyFee),
      sendFunds: Number(pricingData.sendFunds),
      sms: Number(pricingData.sms)
    }).then((result) => {
      if(result.error) {
        console.error(result.error)
        toast.error(result.error.message, {
          position: "top-center"
        })
        setIsLoading(false)
        return
      }
      toast.success("Pricing updated successfully", {
        position: "top-center"
      })
    }).catch((error) => {
      console.error(error)
      toast.error(error.message, {
        position: "top-center"
      })
    }).finally(() => {
      setIsLoading(false)
      setUpdatePricingModalIsOpen(false)
    })
  }

  return (
    <>
  <Card className={`${className}`}>
    <div className='m-10'>
      <div className='flex justify-between'>
        <p className="typo-h2 dark:text-white mb-2">Pricing Info</p>
        <div>
          <p className='typo-h3 dark:text-white mb-2'>Plan</p>
          <p className='typo-h3 dark:text-white mb-2'>{pricing?.plan}</p>
        </div>
      </div>
      {(editable && pricing?.plan === "CUSTOM") &&  <p onClick={() => setUpdatePricingModalIsOpen(true)}className='text-gray-700 dark:text-gray-400 typo-p-small hover:text-gray-400 hover:cursor-pointer dark:hover:text-gray-200'>Update</p>}
      {(editable && pricing?.plan === "BASIC") &&  <p onClick={() => setUpdatePricingModalIsOpen(true)}className='text-gray-700 dark:text-gray-400 typo-p-small hover:text-gray-400 hover:cursor-pointer dark:hover:text-gray-200'>Create Custom Plan</p>}
      <p className="text-grey dark:text-gray-300 typo-p-small mb-5">
        Information regarding how you are charged. 
      </p>
      <div className={styles.container}>
        {!fetching && 
          <>
          {pricing && Object.keys(pricing).map((item: string) => {
            if (item === "userId" || item === "id" || item === "plan") return null
            return (
              <div className={`${styles.row}`} key={item}>
                <div className={`${styles.rowName}`}>{item}</div>
                <div className={`${styles.rowValue}`}>{toUsd(pricing[item as keyof GQlPricing] as any || 0, 5)}</div>
              </div>
            )
          })}
          </>
        }
      </div>
      </div>
    </Card>
    <Modal isOpen={updatePricingModalIsOpen} title={pricing?.plan == "BASIC" ? "Create Pricing" : "Update Pricing"} onClose={() => setUpdatePricingModalIsOpen(false)}>
        <div className="mx-5 my-2">
            <span className="text-grey dark:text-gray-300 typo-p-small">
                {pricing?.plan === "BASIC" ? "Create a custom pricing plan for this user." : "Update the users existing custom pricing plan."}
            </span>
            <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Card Creation</label>
            <CurrencyInput
                id="card-creation"
                name="Card Create"
                placeholder="Please enter a dollar amount"
                value={pricingData?.cardCreation}
                defaultValue={0}
                decimalsLimit={4}
                prefix="$"
                className="h-14 bg-gray-50 typo-p-small border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onValueChange={(value, _name) => setPricingData({
                  ...pricingData,
                  cardCreation: value ?? "0"
                })}
              />
              <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Card Transaction</label>
              <CurrencyInput
                  id="card-creation"
                  name="Card Transaction"
                  placeholder="Please enter a dollar amount"
                  value={pricingData?.cardTransaction}
                  defaultValue={0}
                  decimalsLimit={4}
                  prefix="$"
                  className="h-14 bg-gray-50 typo-p-small border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onValueChange={(value, _name) => setPricingData({
                    ...pricingData,
                    cardTransaction: value ?? "0"
                  })}
                />
              <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Send Funds</label>
              <CurrencyInput
                  id="card-creation"
                  name="Card Transaction"
                  placeholder="Please enter a dollar amount"
                  value={pricingData?.sendFunds}
                  defaultValue={0}
                  decimalsLimit={4}
                  prefix="$"
                  className="h-14 bg-gray-50 typo-p-small border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onValueChange={(value, _name) => setPricingData({
                    ...pricingData,
                    sendFunds: value ?? "0"
                  })}
                />
              <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Sms</label>
              <CurrencyInput
                  id="card-creation"
                  name="Sms"
                  placeholder="Please enter a dollar amount"
                  value={pricingData?.sms}
                  defaultValue={0}
                  decimalsLimit={4}
                  prefix="$"
                  className="h-14 bg-gray-50 typo-p-small border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onValueChange={(value, _name) => setPricingData({
                    ...pricingData,
                    sms: value ?? "0"
                  })}
                />
              <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Offer Opt In Fee</label>
              <CurrencyInput
                id="card-creation"
                name="Offer Opt In Fee"
                placeholder="Please enter a dollar amount"
                value={pricingData?.offerOptIn}
                defaultValue={0}
                decimalsLimit={4}
                prefix="$"
                className="h-14 bg-gray-50 typo-p-small border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onValueChange={(value, _name) => setPricingData({
                  ...pricingData,
                  offerOptIn: value ?? "0"
                })}
              />
              <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Monthly Fee</label>
              <CurrencyInput
                id="card-creation"
                name="Monthly Fee"
                placeholder="Please enter a dollar amount"
                value={pricingData?.monthlyFee}
                defaultValue={0}
                decimalsLimit={4}
                prefix="$"
                className="h-14 bg-gray-50 typo-p-small border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onValueChange={(value, _name) => setPricingData({
                  ...pricingData,
                  monthlyFee: value ?? "0"
                })}
              />
            <div className="flex justify-end mt-2">
              {isLoading ?
                <div className="my-3"><Spinner /></div> 
              :
                <button type="button" onClick={() => onUpdatePricing()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
              }
            </div>
        </div>
      </Modal>
  </>
  )
}

export default Pricing
