import { FC } from 'react'
import BadgeProps, { BadgeVariants } from './Badge.interface'
import styles from './Badge.module.scss'
import Icon from '../Icon/Icon'

const getIcon = (variant: BadgeVariants) => {
  if (variant === BadgeVariants.ACTIVE) return <Icon.Active/>
  if (variant === BadgeVariants.PAUSE) return <Icon.Pause />
  if (variant === BadgeVariants.EXPIRING) return <Icon.Expiring />
  if (variant === BadgeVariants.PENDING) return <Icon.Pending />
  if (variant === BadgeVariants.INCOMPLETE) return <Icon.Expiring />

  return null
}

const Badge: FC<BadgeProps> = ({ label, variant }) => (
  <div className={`${styles.root}`}>
    <span className={`${styles.root} ${styles[variant]}`}>
      {getIcon(variant)}
      {label}
    </span>
  </div>
)

export default Badge
