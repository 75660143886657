import styles from './GoogleSignInButton.module.scss'
const GoogleSignInButton: React.FC = () => {
  const params = new URLSearchParams({
    client_id: "local",
    redirect_uri: location.origin,
    response_type: "token",
    provider: "google",
  })
  return (
    <a
      href={import.meta.env.VITE_AUTH_URL + "/authorize?" + params.toString()}
      rel="noreferrer"
      className='flex justify-center'
    >
      <button type="button" className={styles['login-with-google-btn']} >
        Sign in with Google
      </button>
    </a>
  );
}

export default GoogleSignInButton;



