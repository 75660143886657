import { FC, useRef, useState, useEffect } from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import Icon from '../Icon/Icon'
import BasicModalWrapperProps, {
  BasicModalWrapperVariants,
} from './BasicModalWrapper.interface'
import styles from './BasicModalWrapper.module.scss'
import useIsMobile from '../../utils/hooks'

const BasicModalWrapper: FC<BasicModalWrapperProps> = ({
  children,
  isOpen,
  onClose,
  variant = BasicModalWrapperVariants.PRIMARY,
  isContentRightMargin = true,
}) => {
  const isMobile = useIsMobile()
  const isTablet = useIsMobile('lg')
  let closeCircleSize = 57
  if (isMobile) {
    closeCircleSize = 40
  }
  if (isTablet) {
    closeCircleSize = 48
  }

  const ref = useRef(null)

  const [height, setHeight] = useState<string>('')

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const client: any = ref.current
        if (client && client.scrollHeight && client.scrollHeight !== height) {
          setHeight(`${client.scrollHeight + 0}px`)
        }
      })      
    }
    window.addEventListener('resize', handleResize)
    setTimeout(() => handleResize())
    Modal.setAppElement('body')
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`${styles[variant]}`}
      overlayClassName={styles.overlay}
      style={{ content: { height: height || '100%' } }}
      contentLabel="Example Modal"
      key={height}
    >
      <div className={`${styles.container}`}>
        <button type="button" onClick={onClose} className={styles.closeButton}>
          <Icon.CloseCircle width={closeCircleSize} height={closeCircleSize} />
        </button>
        <div
          ref={ref}
          className={classNames(styles.modalContent, {
            [styles.modalContentAddRightMargin]: isContentRightMargin,
          })}
        >
          {children}
        </div>
      </div>
    </Modal>
  )
}

export default BasicModalWrapper
