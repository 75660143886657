import { FC, useState, useRef, useEffect } from 'react';
import RadioSelectProps from './RadioSelect.interface';
import styles from './RadioSelect.module.scss';
import Radio from '../Radio';

const RadioSelect: FC<RadioSelectProps> = ({ options, name, handleClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (key: string, value: boolean) => {

    handleClick(key, value);
  }
  

  return (
    <div>
      <div className="flex items-center" ref={dropdownRef}>
        <button
          id="dropdownDefault"
          data-dropdown-toggle="dropdown"
          className="mb-4 sm:mb-0 mr-4 inline-flex items-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg typo-p-small px-4 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          type="button"
          onClick={toggleDropdown}
        >
          {name}
          <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
        
      </div>
      {isOpen && (
        <div className='relative'>
          <div id="dropdown" className="z-10 absolute w-56 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
              <h6 className="mb-3 typo-p-small font-medium text-gray-900 dark:text-white">
                Category
              </h6>
              <ul className="space-y-2 typo-p-small" aria-labelledby="dropdownDefault">
                {options.map((option) => (
                  <li className="flex items-center" key={option.value}>
                    <Radio label={option.label} handleChange={(value) => handleChange(option.value, value)} />
                </li>
                ))}
              </ul>
            </div>
        </div>
        )}
    </div>
  );
};

export default RadioSelect;