import { FC } from 'react'
import IconProps from './Icon.interface'

const ErrorBanner: FC<IconProps> = ({
  color = '#EC0909',
  width = 39,
  height = 36,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 39 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.18525 33.768L18.2189 0.768C18.4782 0.294 18.9676 0 19.5 0C20.0304 0
      20.5218 0.294 20.7792 0.768L38.8128 33.768C38.9376 33.998 39 34.25 39 34.502C39
      35.272 38.3974 36 37.5336 36H1.4664C0.6084 36 0 35.28 0 34.502C0 34.25 0.06045
      33.998 0.18525 33.768ZM3.9585 33H35.0396L19.498 4.562L3.9585 33ZM19.5039 20.006C18.6966
      20.006 18.0414 20.678 18.0414 21.506V28.506C18.0414 29.334 18.6966 30.006 19.5039
      30.006C20.3112 30.006 20.9664 29.334 20.9664 28.506V21.506C20.9664 20.678 20.3112
      20.006 19.5039 20.006ZM19.5 14.006C20.5764 14.006 21.45 14.902 21.45 16.006C21.45
      17.11 20.5764 18.006 19.5 18.006C18.4236 18.006 17.55 17.11 17.55 16.006C17.55 14.902
      18.4236 14.006 19.5 14.006Z"
      fill={color}
    />
  </svg>
)

export default ErrorBanner
