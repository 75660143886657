import { useAuth } from '../../providers/Auth';
import { Link } from "react-router-dom";
import styles from "./Navbar.module.scss"
import { useState, useRef, useEffect } from 'react';
import LightDarkToggle from '../LightDarkToggle';

export default function Header() {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>
  const auth = useAuth()
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const handleDropDown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownOpen])

  return (
    <main className={styles.root}>
      <nav className={styles.nav}>
        <div className={styles['nav-wrapper']}>
          <Link to="/" className="flex items-center">
              <img src="/jib_logo_512x512.png" className="h-20 me-4 bg-white p-1 rounded-md" alt="Jib Logo" />
              <span className="self-center typo-p-large font-semibold whitespace-nowrap">Jib Technologies</span>
          </Link>
          <div className='flex gap-3'>
            <a href="https://docs.jibtechnologies.com/" className='typo-p-medium flex items-center justify-between w-full py-2 mr-6 text-white rounded hover:bg-gray-100 cursor-pointer md:hover:bg-transparent md:border-0 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent'>Docs</a>
            <LightDarkToggle />
            {auth.session ?
            <>
              <button 
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className={styles['mobile-dropdown']}
              >
                  <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                  </svg>
              </button>
              <div className={styles['dropdown']}>
                <ul className={styles.list}>
                  <li>
                  </li>
                    <li>
                    <div 
                        ref={ref}
                        onClick={handleDropDown}
                      >
                        <button 
                        id="dropdownNavbarLink"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        className={styles['dropdown-button']}
                        >
                          
                          {(auth.userData?.firstName && auth.userData?.lastName) ? `${auth.userData?.firstName} ${auth.userData?.lastName}` : "User"}
                          <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                          </svg>
                        </button>
                      </div>
                    </li>
                </ul>
              </div>
            </>
            :
            <Link to='/login' className='typo-p-medium tracking-tight text-white dark:text-white cursor-pointer;'>Login</Link>
            }
          </div>
          
        </div>
        <div className={styles['dropdown-container']}>
        {dropdownOpen && (
          <div  className={styles['dropdown-option']} ref={ref}>
            <ul>
              <li><Link to="/" onClick={() => setDropdownOpen(false)}>Dashboard</Link></li>
              <li><Link to="/lifecycle" onClick={() => setDropdownOpen(false)}>Lifecycle</Link></li>
              <li><Link to="/api-keys" onClick={() => setDropdownOpen(false)}>Api Keys</Link></li>
              <li><Link to="/profile" onClick={() => setDropdownOpen(false)}>Profile</Link></li>
              {import.meta.env.VITE_STAGE !== "prod" && <li><Link to="/simulate" onClick={() => setDropdownOpen(false)}>Simulate</Link></li>}
              <li onClick={() => {
                auth.signOut()
                setDropdownOpen(false)
              }}>Logout</li>
            </ul>
          </div>
        )}
        </div>
      </nav>
          </main>
  );
}