import React, { FC } from 'react'
import IconProps from './Icon.interface'

const DinersClubIcon: FC<IconProps> = ({
  width = 46,
  height = 28,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 46 28"
    className={className}
  >
    <g clipPath="url(#clip0_1891_34552)">
      <mask id="path-1-inside-1_1891_34552" fill="white">
        <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" />
      </mask>
      <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" fill="white" />
      <path d="M3.5 28V27V28ZM0 24.5H-1H0ZM3.5 0V-1V0ZM46 24.5H45H46ZM42.5 27H3.5V29H42.5V27ZM3.5 27C2.83696 27 2.20107 26.7366 1.73223 26.2678L0.318019 27.682C1.16193 28.5259 2.30653 29 3.5 29V27ZM1.73223 26.2678C1.26339 25.7989 1 25.163 1 24.5H-1C-1 25.6935 -0.525894 26.8381 0.318019 27.682L1.73223 26.2678ZM1 24.5V3.5H-1V24.5H1ZM1 3.5C1 2.83696 1.26339 2.20107 1.73223 1.73223L0.318019 0.318019C-0.525894 1.16193 -1 2.30653 -1 3.5H1ZM1.73223 1.73223C2.20107 1.26339 2.83696 1 3.5 1V-1C2.30653 -1 1.16193 -0.525894 0.318019 0.318019L1.73223 1.73223ZM3.5 1H42.5V-1H3.5V1ZM42.5 1C43.163 1 43.7989 1.26339 44.2678 1.73223L45.682 0.318019C44.8381 -0.525894 43.6935 -1 42.5 -1V1ZM44.2678 1.73223C44.7366 2.20107 45 2.83696 45 3.5H47C47 2.30652 46.5259 1.16193 45.682 0.318019L44.2678 1.73223ZM45 3.5V24.5H47V3.5H45ZM45 24.5C45 25.163 44.7366 25.7989 44.2678 26.2678L45.682 27.682C46.5259 26.8381 47 25.6935 47 24.5H45ZM44.2678 26.2678C43.7989 26.7366 43.163 27 42.5 27V29C43.6935 29 44.8381 28.5259 45.682 27.682L44.2678 26.2678Z" fill="#E8E8E8" mask="url(#path-1-inside-1_1891_34552)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.7139 23H23.902C28.8787 23 32.8445 19.0089 33 14.0788V14.0006C32.9222 8.99217 28.8787 4.92285 23.902 5.00111H20.3251C15.5817 5.15762 11.8492 9.22694 12.0047 14.0788C12.0047 18.9307 15.8927 22.9217 20.7139 23ZM13.5599 14.0005C13.5599 9.93121 16.9035 6.5662 20.9471 6.5662C24.9906 6.5662 28.3343 9.93121 28.3343 14.0005C28.3343 18.0698 24.9906 21.4349 20.9471 21.4349C16.9035 21.4349 13.5599 18.0698 13.5599 14.0005ZM22.1135 19.0089V9.07041C24.8352 9.77471 26.5459 12.5137 25.846 15.2526C25.3795 17.1308 23.9798 18.5394 22.1135 19.0089ZM16.1259 12.7484C15.4261 15.4874 17.059 18.3046 19.7807 19.0089V9.07041C17.9922 9.53995 16.5925 10.9486 16.1259 12.7484Z" fill="#145095" />
    </g>
    <defs>
      <clipPath id="clip0_1891_34552">
        <rect width="46" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default DinersClubIcon
