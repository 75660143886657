export enum OfferCardVariants {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  EXPIRING = 'Expiring',
  PAUSE = 'Paused',
  PAST = 'Past',
  INCOMPLETE = 'Incomplete',
  CARD_LIMIT_REACHED = 'CardLimitReached',
  END_DATE_REACHED = 'EndDateReached',
  TERMINATED = 'Terminated',
  PAYMENT_PENDING = 'PaymentPending',
}

export default interface OfferCardProps {
  title: string,
  topRightLinkCallback: (e?: any) => void,
  cardsCreated: string,
  budgetRemains: string | number,
  cardsRedeemedPerCent: string
  cardsRedeemed: string,
  variant: OfferCardVariants,
  addFundsCallBack?: (e?: any) => void,
}
