import React, { FC } from 'react'
import IconProps from './Icon.interface'

const DiscoverIcon: FC<IconProps> = ({
  width = 46,
  height = 28,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 46 28"
    className={className}
  >
    <g clipPath="url(#clip0_1891_34043)">
      <mask id="path-1-inside-1_1891_34043" fill="white">
        <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" />
      </mask>
      <path d="M42.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5L0 3.5C0 2.57174 0.368749 1.6815 1.02513 1.02513C1.6815 0.368749 2.57174 0 3.5 0L42.5 0C43.4283 0 44.3185 0.368749 44.9749 1.02513C45.6312 1.6815 46 2.57174 46 3.5V24.5C46 25.4283 45.6312 26.3185 44.9749 26.9749C44.3185 27.6313 43.4283 28 42.5 28Z" fill="#E8E8E8" />
      <path d="M3.5 28V27V28ZM0 24.5H-1H0ZM3.5 0V-1V0ZM46 24.5H45H46ZM42.5 27H3.5V29H42.5V27ZM3.5 27C2.83696 27 2.20107 26.7366 1.73223 26.2678L0.318019 27.682C1.16193 28.5259 2.30653 29 3.5 29V27ZM1.73223 26.2678C1.26339 25.7989 1 25.163 1 24.5H-1C-1 25.6935 -0.525894 26.8381 0.318019 27.682L1.73223 26.2678ZM1 24.5V3.5H-1V24.5H1ZM1 3.5C1 2.83696 1.26339 2.20107 1.73223 1.73223L0.318019 0.318019C-0.525894 1.16193 -1 2.30653 -1 3.5H1ZM1.73223 1.73223C2.20107 1.26339 2.83696 1 3.5 1V-1C2.30653 -1 1.16193 -0.525894 0.318019 0.318019L1.73223 1.73223ZM3.5 1H42.5V-1H3.5V1ZM42.5 1C43.163 1 43.7989 1.26339 44.2678 1.73223L45.682 0.318019C44.8381 -0.525894 43.6935 -1 42.5 -1V1ZM44.2678 1.73223C44.7366 2.20107 45 2.83696 45 3.5H47C47 2.30652 46.5259 1.16193 45.682 0.318019L44.2678 1.73223ZM45 3.5V24.5H47V3.5H45ZM45 24.5C45 25.163 44.7366 25.7989 44.2678 26.2678L45.682 27.682C46.5259 26.8381 47 25.6935 47 24.5H45ZM44.2678 26.2678C43.7989 26.7366 43.163 27 42.5 27V29C43.6935 29 44.8381 28.5259 45.682 27.682L44.2678 26.2678Z" fill="#E8E8E8" mask="url(#path-1-inside-1_1891_34043)" />
    </g>
    <mask id="mask0_1891_34043" maskUnits="userSpaceOnUse" x="1" y="1" width="44" height="26">
      <path d="M3.5 1C2.83696 1 2.20107 1.26339 1.73223 1.73223C1.26339 2.20107 1 2.83696 1 3.5L1 24.5C1 25.163 1.26339 25.7989 1.73223 26.2678C2.20107 26.7366 2.83696 27 3.5 27H42.5C43.163 27 43.7989 26.7366 44.2678 26.2678C44.7366 25.7989 45 25.163 45 24.5V3.5C45 2.83696 44.7366 2.20107 44.2678 1.73223C43.7989 1.26339 43.163 1 42.5 1L3.5 1Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1891_34043)">
      <mask id="mask1_1891_34043" maskUnits="userSpaceOnUse" x="0" y="0" width="46" height="29">
        <path d="M45.9999 0H0.608643V29H45.9999V0Z" fill="white" />
      </mask>
      <g mask="url(#mask1_1891_34043)">
        <path d="M45.8317 0.16806H0.776611V28.9159H45.8317V0.16806Z" fill="white" />
      </g>
      <mask id="mask2_1891_34043" maskUnits="userSpaceOnUse" x="0" y="0" width="46" height="29">
        <path d="M45.9999 0H0.608643V29H45.9999V0Z" fill="white" />
      </mask>
      <g mask="url(#mask2_1891_34043)">
        <mask id="mask3_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask4_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask5_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask6_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask7_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask8_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask9_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask10_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask11_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask12_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask13_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask14_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask14_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask13_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask12_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask11_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask10_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask9_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask8_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask7_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask6_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask5_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask4_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask3_1891_34043)">
          <path d="M45.6638 16.1391C45.6638 16.1391 33.3073 24.797 10.7798 28.7478H45.6638V16.1391Z" fill="#EE7623" />
        </g>
        <mask id="mask15_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask16_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask17_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask18_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask19_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask20_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask21_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask22_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask23_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask24_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask25_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask26_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask26_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask25_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask24_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask23_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask22_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask21_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask20_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask19_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask18_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask17_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask16_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask15_1891_34043)">
          <path d="M45.8318 -6.10352e-05H0.608643V28.9999H45.9999V-6.10352e-05H45.8318ZM45.6637 0.336166V28.7478H0.944874C0.944874 28.4115 0.944874 0.672398 0.944874 0.336166C1.28111 0.336166 45.3275 0.336166 45.6637 0.336166Z" fill="#231F20" />
        </g>
        <mask id="mask27_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask28_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask29_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask30_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask31_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask32_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask33_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask34_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask35_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask36_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask37_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask38_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask38_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask37_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask36_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask35_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask34_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask33_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask32_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask31_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask30_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask29_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask28_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask27_1891_34043)">
          <path d="M4.55924 8.91H2.70996V15.4665H4.55924C5.56793 15.4665 6.2404 15.2144 6.91286 14.71C7.66938 14.1216 8.08967 13.197 8.08967 12.1883C8.08967 10.2549 6.66069 8.91 4.55924 8.91ZM6.07228 13.8694C5.65199 14.2057 5.14764 14.3738 4.30706 14.3738H3.97083V10.0028H4.30706C5.14764 10.0028 5.65199 10.1709 6.07228 10.5071C6.49257 10.9274 6.74474 11.5158 6.74474 12.1042C6.74474 12.8607 6.49257 13.4491 6.07228 13.8694Z" fill="#231F20" />
        </g>
        <mask id="mask39_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask40_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask41_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask42_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask43_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask44_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask45_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask46_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask47_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask48_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask49_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask50_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask50_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask49_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask48_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask47_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask46_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask45_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask44_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask43_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask42_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask41_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask40_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask39_1891_34043)">
          <path d="M9.93909 8.91H8.67822V15.4665H9.93909V8.91Z" fill="#231F20" />
        </g>
        <mask id="mask51_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask52_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask53_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask54_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask55_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask56_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask57_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask58_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask59_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask60_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask61_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask62_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask62_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask61_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask60_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask59_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask58_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask57_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask56_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask55_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask54_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask53_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask52_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask51_1891_34043)">
          <path d="M13.1332 11.4319C12.3767 11.1797 12.1245 10.9276 12.1245 10.5913C12.1245 10.171 12.5448 9.83482 13.0492 9.83482C13.4694 9.83482 13.7216 10.0029 14.0579 10.3392L14.7303 9.49858C14.1419 8.99423 13.5535 8.74207 12.797 8.74207C11.6202 8.74207 10.7796 9.58264 10.7796 10.5913C10.7796 11.516 11.1999 11.9363 12.3767 12.3566C12.881 12.5247 13.1332 12.6928 13.3013 12.6928C13.5535 12.8609 13.7216 13.1131 13.7216 13.3653C13.7216 13.8696 13.3013 14.2899 12.7129 14.2899C12.1245 14.2899 11.6202 13.9537 11.2839 13.4493L10.4434 14.2058C11.0318 15.0464 11.7042 15.4667 12.7129 15.4667C14.0579 15.4667 14.9825 14.5421 14.9825 13.2812C14.9825 12.4406 14.5622 11.9363 13.1332 11.4319Z" fill="#231F20" />
        </g>
        <mask id="mask63_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask64_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask65_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask66_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask67_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask68_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask69_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask70_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask71_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask72_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask73_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask74_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask74_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask73_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask72_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask71_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask70_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask69_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask68_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask67_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask66_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask65_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask64_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask63_1891_34043)">
          <path d="M15.4031 12.1883C15.4031 14.1216 16.9161 15.6347 18.8495 15.6347C19.4379 15.6347 19.8581 15.5506 20.4466 15.2144V13.7854C19.9422 14.2898 19.5219 14.5419 18.9335 14.5419C17.6726 14.5419 16.748 13.6173 16.748 12.2724C16.748 11.0115 17.6726 10.0028 18.9335 10.0028C19.5219 10.0028 20.0263 10.255 20.5306 10.7593V9.24628C19.9422 8.99411 19.5219 8.82599 18.9335 8.82599C16.9161 8.74193 15.4031 10.339 15.4031 12.1883Z" fill="#231F20" />
        </g>
        <mask id="mask75_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask76_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask77_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask78_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask79_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask80_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask81_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask82_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask83_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask84_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask85_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask86_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask86_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask85_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask84_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask83_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask82_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask81_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask80_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask79_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask78_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask77_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask76_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask75_1891_34043)">
          <path d="M30.6175 13.3651L28.8523 8.91H27.5073L30.2812 15.6346H30.9537L33.8117 8.91H32.3827L30.6175 13.3651Z" fill="#231F20" />
        </g>
        <mask id="mask87_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask88_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask89_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask90_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask91_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask92_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask93_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask94_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask95_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask96_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask97_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask98_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask98_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask97_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask96_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask95_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask94_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask93_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask92_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask91_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask90_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask89_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask88_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask87_1891_34043)">
          <path d="M34.3999 15.4665H38.0144V14.3738H35.6608V12.6086H37.9303V11.5158H35.6608V10.0028H38.0144V8.91H34.3999V15.4665Z" fill="#231F20" />
        </g>
        <mask id="mask99_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask100_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask101_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask102_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask103_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask104_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask105_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask106_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask107_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask108_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask109_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask110_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask110_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask109_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask108_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask107_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask106_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask105_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask104_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask103_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask102_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask101_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask100_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask99_1891_34043)">
          <path d="M43.0582 10.8433C43.0582 9.58247 42.2176 8.91 40.7046 8.91H38.7712V15.4665H40.1162V12.8607H40.2843L42.0495 15.4665H43.6466L41.5451 12.6926C42.5538 12.5245 43.0582 11.852 43.0582 10.8433ZM40.5365 11.9361H40.1162V9.9187H40.5365C41.293 9.9187 41.7973 10.2549 41.7973 10.9274C41.7973 11.5999 41.293 11.9361 40.5365 11.9361Z" fill="#231F20" />
        </g>
        <mask id="mask111_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask112_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask113_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask114_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask115_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask116_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask117_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask118_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask119_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask120_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask121_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask122_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask122_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask121_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask120_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask119_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask118_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask117_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask116_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask115_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask114_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask113_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask112_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask111_1891_34043)">
          <path d="M27.8436 12.1883C27.8436 14.1216 26.2465 15.7187 24.3132 15.7187C22.3798 15.7187 20.7827 14.1216 20.7827 12.1883C20.7827 10.2549 22.3798 8.65784 24.3132 8.65784C26.3305 8.74189 27.8436 10.2549 27.8436 12.1883Z" fill="#EE7623" />
        </g>
        <mask id="mask123_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask124_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask125_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask126_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask127_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask128_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask129_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask130_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask131_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask132_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask133_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask134_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask134_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask133_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask132_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask131_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask130_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask129_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask128_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask127_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask126_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask125_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask124_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask123_1891_34043)">
          <path d="M43.7306 9.07812C43.7306 8.99406 43.6465 8.91 43.4784 8.91H43.3103V9.49841H43.4784V9.24624L43.6465 9.49841H43.8146L43.6465 9.24624C43.7306 9.16219 43.7306 9.16217 43.7306 9.07812ZM43.5625 9.16218V8.99407C43.6465 8.99407 43.6465 8.99406 43.5625 9.16218C43.6465 9.07812 43.5625 9.16218 43.5625 9.16218Z" fill="#231F20" />
        </g>
        <mask id="mask135_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
          <mask id="mask136_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
            <mask id="mask137_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
              <mask id="mask138_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                <mask id="mask139_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                  <mask id="mask140_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                    <mask id="mask141_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                      <mask id="mask142_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                        <mask id="mask143_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                          <mask id="mask144_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                            <mask id="mask145_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                              <mask id="mask146_1891_34043" maskUnits="userSpaceOnUse" x="-54" y="-76" width="156" height="202">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </mask>
                              <g mask="url(#mask146_1891_34043)">
                                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                              </g>
                            </mask>
                            <g mask="url(#mask145_1891_34043)">
                              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                            </g>
                          </mask>
                          <g mask="url(#mask144_1891_34043)">
                            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                          </g>
                        </mask>
                        <g mask="url(#mask143_1891_34043)">
                          <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                        </g>
                      </mask>
                      <g mask="url(#mask142_1891_34043)">
                        <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                      </g>
                    </mask>
                    <g mask="url(#mask141_1891_34043)">
                      <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask140_1891_34043)">
                    <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                  </g>
                </mask>
                <g mask="url(#mask139_1891_34043)">
                  <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
                </g>
              </mask>
              <g mask="url(#mask138_1891_34043)">
                <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask137_1891_34043)">
              <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask136_1891_34043)">
            <path d="M101.563 -75.9885H-53.7766V125.078H101.563V-75.9885Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask135_1891_34043)">
          <path d="M43.5622 8.65784C43.31 8.65784 43.0579 8.91002 43.0579 9.16219C43.0579 9.41436 43.31 9.66654 43.5622 9.66654C43.8144 9.66654 44.0666 9.41436 44.0666 9.16219C44.0666 8.91002 43.8144 8.65784 43.5622 8.65784ZM43.5622 9.58248C43.31 9.58248 43.1419 9.41436 43.1419 9.16219C43.1419 8.91002 43.31 8.7419 43.5622 8.7419C43.8144 8.7419 43.9825 8.91002 43.9825 9.16219C43.9825 9.41436 43.8144 9.58248 43.5622 9.58248Z" fill="#231F20" />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1891_34043">
        <rect width="46" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default DiscoverIcon
