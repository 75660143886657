import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const PhoneIcon: FunctionComponent<IconProps> = ({
  color = '#26346A',
  width = 24,
  height = 24,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM16.5 17.311L14.74 13.914L13.708 14.419C12.588 14.962 10.308 10.509 11.403 9.922L12.445 9.409L10.698 6L9.645 6.52C6.044 8.397 11.762 19.511 15.445 17.828L16.5 17.311Z"
      fill={color}
    />
  </svg>
)

export default PhoneIcon
