import { FC, useState } from 'react'
import OfferPermissionsFormProps from './OfferPermissionsForm.interface'
import styles from './OfferPermissionsForm.module.scss'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { MultiSelectOption } from "../../components/MultiSelect/MultiSelect.interface"
import MultiSelect from '../MultiSelect/MultiSelect'
import { useNavigate } from 'react-router-dom'
import Spinner from '../Spinner'
import { useMutation } from 'urql'
import { addOfferPermissionsMutation } from '../../graphql/mutations'
import { toast } from 'react-toastify'

interface IStaticData {
  // descriptorContains: string[]
  allowedMerchants: MultiSelectOption[]
  [key: string]: any
}

const OfferPermissionsForm: FC<OfferPermissionsFormProps> = ({merchants, offerId, finallyCallback, selectedMerchants}) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [staticData, setStaticData] = useState<IStaticData>({
    // descriptorContains: [],
    allowedMerchants: selectedMerchants.map(selectedMerchants => ({ value: selectedMerchants.id, label: selectedMerchants.descriptor })) as MultiSelectOption[]
  })

  const [_, addOfferPermissions] = useMutation(addOfferPermissionsMutation)

  const merchantOptions = merchants.map(merchant => ({ value: merchant.id, label: merchant.descriptor }))

  const schema = yup.object().shape({
    // descriptorContains: yup.string().email().required(),
    allowedMerchants: yup.array()
        .of(
            yup.object().shape({
                value: yup.object().required(),
                label: yup.string().required()
            }),
        ).required()
  })
  const formOptions = { resolver: yupResolver(schema) }
  const { handleSubmit, formState: {errors}, control, reset, setValue } = useForm<IStaticData>(formOptions)


  const update = async () => {
    setIsLoading(true)
    addOfferPermissions({
      merchantIds: staticData.allowedMerchants.map(merchant => merchant.value),
      offerId
    }).then((result) => {
      if (result.error) {
        console.error(result.error)
        toast.error("Something went wrong")
      } else if (result.data?.addOfferPermissions.__typename === "BaseError") {
        toast.error(result.data.addOfferPermissions.message)
      } else {
        toast.success("Offer permissions added")
      }
    }).finally(() => {
      setIsLoading(false)
      reset()
      finallyCallback()
    })
  }

  return (
    <div className={`${styles.root}`}>
      <p className={styles.subtitle}>Set permissions around where your offer can be redeemed.</p>
      <h2 className={styles['input-title']}>SPECIFY TYPE OF MERCHANT</h2>
      { merchants.length > 0 ? (
        <>
        <Controller 
          name="allowedMerchants"
          control={control}
          render={({ field: { onChange, name, ref }}) => (
              <MultiSelect 
                  label="Allowed merchants (select all that apply)"
                  name={name}
                  description="Authorize your cards at specific types of businesses."
                  errorMessage={errors.allowedMerchants?.message}
                  isRequired
                  isError={!!errors.allowedMerchants}
                  value={staticData.allowedMerchants}
                  options={merchantOptions}
                  onChange={(value) => {
                      onChange(value)
                      setStaticData({ ...staticData, allowedMerchants: value })
                  }}
                  innerRef={ref}
                  
              />
            )}
          />
          <div className="flex justify-end">
            {isLoading ? 
              <div className="my-3"><Spinner /></div> 
            : 
              <button type="button" onClick={() => update()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center">
                <span className="ml-2">Update</span>
              </button>
            }
            </div>
        </>

      ) : (
        <p className={styles.subtitle}>No merchants available, please go to <span className='text-blue-400 cursor-pointer' onClick={() => navigate("/profile")}>your profile page</span> to use an existing, or to create a new merchant card to and use at the merchant point of sale to allow us to capture the merchant information.</p>
      )}
      
    </div>
  )
}

export default OfferPermissionsForm
