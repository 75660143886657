import { FC } from 'react'
import Button from '../Button/Button'
import { ButtonVariants } from '../Button/Button.interface'
import styles from './NoOffersPlaceholder.module.scss'
import NoOffersPlaceholderProps from './NoOffersPlaceholder.interface'

const NoOffersPlaceholder: FC<NoOffersPlaceholderProps> = ({
  title,
  content,
  buttonLabel,
  onClick,
}) => (
  <div className={styles.root}>
    <span className={styles.title}>{title}</span>
    <span className={styles.content}>{content}</span>
    {buttonLabel && (
      <Button
        label={buttonLabel}
        onClick={onClick}
        variant={ButtonVariants.PRIMARY}
      />
    )}
  </div>
)

export default NoOffersPlaceholder
