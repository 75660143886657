import React from "react"
import ReactDOM from "react-dom/client"
import './styles/globals.scss'
import "tailwindcss/tailwind.css"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { AuthProvider } from './providers/Auth'
import Root from "./pages/root"
import Lifecycle from "./pages/lifecycle"
import {
  Client,
  fetchExchange,
  Provider as UrqlProvider,
  
} from "urql";
import { cacheExchange, offlineExchange } from '@urql/exchange-graphcache';
import { GraphCacheConfig, } from "./graphql/gql/graphql";
import { ToastContainer } from 'react-toastify';
import { listApiKeysQuery, listMerchantCardsQuery, sessionQuery } from "./graphql/queries";
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from "./pages/errorPage";
import LoginPage from "./pages/login"
import PrivateRoute from "./components/PrivateRoute";
import Profile from "./pages/profile";
import ApiKeys from "./pages/apiKeys";
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import Dashboard from "./pages/dashboard/dashboard";
import * as Sentry from "@sentry/react";
import User from "./pages/user";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import schema from "./graphql/introspection.json";
import VerifyPhoneNumber from "./pages/verifyPhoneNumber";
import Simulate from "./pages/simulate";
import VerifyPhoneNumberComplete from "./pages/verifyPhoneNumberComplete";
import Checkout from "./pages/checkout";
import { ThemeProvider } from "./providers/Theme";
import OfferDetail from "./pages/offerDetail";

const isLocal = Boolean(import.meta.env.VITE_IS_LOCAL!);
if (!isLocal) {
  Sentry.init({
    dsn: import.meta.env.VITE_DASHBOARD_DSN!,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const storage = makeDefaultStorage({
  idbName: 'graphcache-v3', // The name of the IndexedDB database
  maxAge: 7, // The maximum age of the persisted data in days
});

const urql = new Client({
  url: `${import.meta.env.VITE_API_URL!}/graphql`,
  exchanges: [
    cacheExchange<GraphCacheConfig>({
      schema,
      storage,
      resolvers: {
        Query: {
          cardPrograms: relayPagination(),
        }
      },
      keys: {
        PaginatedCardProgramType: () => null,
        PaginatedTransactions: () => null,
        PaginatedAccounts: () => null,
        FunnelData: () => null,
        PaginatedUsers: () => null,
        QueryGetOfferSuccess: (data) => data.data?.id ?? null,
        QueryPrimaryAccountSuccess: (data) => data.data?.id ?? null,
        BaseError: () => null,
        PaginatedLifecycleType: () => null,
        QueryLifecyclesSuccess: () => null,
        QueryFunnelDataSuccess: () => null,
        QueryApiKeysSuccess: () => null,
        QueryMerchantsSuccess: () => null,
        PaginatedMerchants: () => null,
        QueryCardProgramsSuccess: () => null,
        QueryOtherUserTransactionsSuccess: () => null,
        QueryTransactionsSuccess: () => null,
        QueryUsersSuccess: () => null,
        QueryAccountsSuccess: () => null,
        QueryGetPaymentIntentSuccess: (data) => data.data?.id ?? null,
        MutationAddFundsToAccountSuccess: (data) => data.data?.id ?? null,
      },
      updates: {
        Mutation: {
          createMerchantCard(result, _args, cache, _info) {
            cache.updateQuery({ query: listMerchantCardsQuery }, (data) => {
              if (result.createMerchantCard.__typename === 'MutationCreateMerchantCardSuccess') {
                if (data?.merchantCards?.__typename === "QueryMerchantCardsSuccess") {
                  data.merchantCards.data.push(result.createMerchantCard.data as any) // Todo: try and fix this
                }
              }
              return data
            })
          },
          createApiKey(result, _args, cache, _info) {
            cache.updateQuery({ query: listApiKeysQuery }, (data) => {
              if (result.createApiKey.__typename === 'MutationCreateApiKeySuccess') {
                /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
                const { key, ...rest} = result.createApiKey.data! // make sure not to save key
                if (data?.apiKeys.__typename === "QueryApiKeysSuccess" ) {
                  data?.apiKeys.data.push(rest as any) // Todo: try and fix this
                }
              }
              return data
            })
          },
          deleteApiKey(result, _args,  cache, _info) {
            let deletedKeyId: string | undefined = undefined;
            if (result.deleteApiKey.__typename === 'MutationDeleteApiKeySuccess') {
              deletedKeyId = result.deleteApiKey.data;
            }
            cache.updateQuery({ query: listApiKeysQuery }, (data) => {
              if (data?.apiKeys && data.apiKeys.__typename === "QueryApiKeysSuccess") {
                const index = data.apiKeys.data.findIndex(apiKey => apiKey.id === deletedKeyId);
                if (index > -1) {
                  // Remove the API key with the matching id
                  data.apiKeys.data.splice(index, 1);
                }
              }
              return data;
            });
          },
          createCardProgram(_result, _args, cache, _info) {
            
            cache
              .inspectFields("Query")
              .filter(field => field.fieldName === 'cardPrograms')
              .forEach(field => {
                cache.invalidate("Query", field.fieldName, field.arguments);
              });
          },
          createOffer(_result, _args, cache, _info) {
            cache
              .inspectFields("Query")
              .filter(field => field.fieldName === 'offers')
              .forEach(field => {
                cache.invalidate("Query", field.fieldName, field.arguments);
              });
          },
          createCustomPricingPlan(_result, _args, cache, _info) {
              cache
                .inspectFields("Query")
                .filter(field => field.fieldName === 'pricing')
                .forEach(field => {
                  cache.invalidate("Query", field.fieldName, field.arguments);
                });
          },
          addFundsToAccount(result, _args, cache, _info) {
            cache
              .inspectFields("Query")
              .filter(field => field.fieldName === 'primaryAccount')
              .forEach(field => {
                cache.invalidate("Query", field.fieldName, field.arguments);
              });
            
          },
          addPhoneNumberToUser(result, _args, cache, _info) {
            cache.updateQuery({ query: sessionQuery }, (data) => {
              if (result.addPhoneNumberToUser.__typename === 'MutationAddPhoneNumberToUserSuccess') {
                if (data?.session){
                  data.session = {
                    ...data?.session,
                    phoneNumber: result.addPhoneNumberToUser.data?.phoneNumber
                  }
                }
              }
              return data
            })
          }
        } 

      } 

    }),
    fetchExchange
  ],
  fetchOptions: () => {
    const token = localStorage.getItem("session")
    return {
      headers: { 
        Authorization: token ? `Bearer ${token}` : '',
        "Access-Control-Allow-Origin": "*"
      },
    }
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <PrivateRoute />,
        children: [
          {
            path: "/verify-phone-number",
            element: <VerifyPhoneNumber />
          },
          {
            path: "/verify-phone-number-complete",
            element: <VerifyPhoneNumberComplete />
          },
          {
            path: "/checkout",
            children: [
              {
                path: ":id",
                element: <Checkout />
              }
            ]
          },
          {
            path: "/lifecycle",
            element: <Lifecycle />
          },
          {
            path: "/offers/:id",
            element: <OfferDetail />
          },
          {
            path: "/profile",
            element: <Profile />
          },
          {
            path: "/api-keys",
            element: <ApiKeys />
          },
          {
            path: "/simulate",
            element: <Simulate />
          },
          {
            path: "/",
            element: <Dashboard />
          },
          {
            path: "/users",
            children: [
              {
                path: "",
                element: <div>Users</div>
              },
              {
                path: ":userId",
                element: <User />
              }
            ]
          }
        ]
      },
      {
        path: "/login",
        element: <LoginPage />
      },
    ]
  }
  
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <UrqlProvider value={urql}>
      <ThemeProvider>
        <AuthProvider>
          <RouterProvider router={router} />
          <ToastContainer />
        </AuthProvider>
      </ThemeProvider>
    </UrqlProvider>
  </React.StrictMode>
)
