import { useState, useEffect, FC } from 'react'
import QRCode from 'qrcode'
import QrCodeProps, { QrCodeVariants } from './QrCode.interface'
import styles from './QrCode.module.scss'

const QrCode: FC<QrCodeProps> = ({ href = '', variant }) => {
  const colorLight = variant === QrCodeVariants.WHITE_BACKGROUND ? "#FFFFFF" : '#F5F7F9'

  const options = {
    width: 148,
    color: {
      dark: "#000000",
      light: colorLight,
    },
  }

  const [src, setSrc] = useState('')

  useEffect(() => {
    QRCode.toDataURL(href, options).then((setSrc))
  }, [href, variant])

  const copyToClipBoard = () => {
    if (navigator) {
      navigator.clipboard.writeText(href)
    }
  }

  return (
    <div className={`${styles.root} ${styles[variant]}`}>
      <div className={`${styles.container}`}>
        <div className={`${styles.imageContainer}`}>
          <img src={src} alt={href} className={`${styles.image}`} />
        </div>
        <div className={`${styles.text}`}>{href}</div>
        <div className={`${styles.ctaWrapper}`}>
          <button
            type="button"
            className={`${styles.copy}`}
            onClick={copyToClipBoard}
          >
            Copy
          </button>
          <a
            href={src}
            download
            className={`${styles.download}`}
          >
            Download
          </a>
        </div>
      </div>
    </div>
  )
}

export default QrCode
