import StripeCheckoutFormProps from './StripeCheckoutForm.interface'
import styles from './StripeCheckoutForm.module.scss'
import { useEffect, useState, FC } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Spinner from '../../components/Spinner';
import { toast } from 'react-toastify';

const StripeCheckoutForm: FC<StripeCheckoutFormProps> = ( { onChange }) => {

    const stripe = useStripe();
    const elements = useElements();
  
    const [isLoading, setIsLoading] = useState(false);

 
  
    const handleSubmit = async (e: any) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.protocol}//${window.location.host}`,
        },
      });
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        toast.error(error.message ?? "An unexpected error occurred.");
      } else {
        toast.error("An unexpected error occurred.");
      }
      setIsLoading(false);
    };
  
    return (
      <form id="payment-form" onSubmit={handleSubmit} className={styles.root}>
  
        <PaymentElement id="payment-element" 
          options={{
          layout: "tabs",
          }}
          onChange={onChange}
          />
        <div className="flex justify-end mt-2">
              {isLoading ?
                <div className="my-3"><Spinner /></div> 
              :
        <button disabled={isLoading || !stripe || !elements} id="submit" className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
          Pay Now
        </button>
              }
        </div>
      </form>
    );
}

export default StripeCheckoutForm
