import React, { FC } from 'react'
import IconProps from './Icon.interface'

const LockIcon: FC<IconProps> = ({
  color = '#6A6A6A',
  width = 16,
  height = 21,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 16 21"
    className={className}
  >
    <path
      fill={color}
      d="M14 7h-1V5A5 5 0 003 5v2H2a2.006 2.006 0 00-2 2v10a2.006 2.006 0 002 2h12a2.006 2.006 0 002-2V9a2.006 2.006 0 00-2-2zM5 5a3 3 0 116 0v2H5V5zm9 14H2V9h12v10zm-6-3a2 2 0 100-4 2 2 0 000 4z"
    />
  </svg>
)

export default LockIcon
