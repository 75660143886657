import { FC } from 'react'
import IconProps from './Icon.interface'

const Active: FC<IconProps> = ({
  color = '#4FE841',
  width = 14,
  height = 20,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.3991 9.00012H9.00006L9.99206 0.557118C10.0461 0.0751176 9.45106 -0.192882 9.12506
      0.165118L0.157061 9.99612C-0.192939 10.3821 0.0800606 11.0001 0.601061 11.0001H5.00006L5.00806
      19.4431C4.95406 19.9251 5.54906 20.1931 5.87506 19.8351L13.8431 10.0041C14.1931 9.61812 13.9201
      9.00012 13.3991 9.00012Z"
      fill={color}
    />
  </svg>
)

export default Active
