import { graphql } from "./gql"

export const listLifecyclesQuery = graphql(/* GraphQL */ `
  query listLifecycles($pageNumber: Int!, $offset: Int!) {
    lifecycles(pageNumber: $pageNumber, offset: $offset) {
      __typename
      ... on QueryLifecyclesSuccess {
        __typename
        data {
          __typename
          count
          lifecycles {
            __typename
            id
            status
            timeCreated
            newCard
            card {
              user {
                phoneNumber
              }
            }
          }
        }
      }
      __typename
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const listMerchantsQuery = graphql(/* GraphQL */ `
  query merchants($limit: Int!, $offset: Int!) {
    merchants(limit: $limit, offset: $offset) {
      __typename
      ... on QueryMerchantsSuccess {
        __typename
        data {
          __typename
          count
          merchants {
            __typename
            id
            userId
            acceptorId
            city
            country
            descriptor
            mcc
            state
            timeCreated
            timeUpdated
          }
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const listMerchantCardsQuery = graphql(/* GraphQL */ `
  query merchantCards {
    merchantCards {
      __typename
      ... on QueryMerchantCardsSuccess {
        __typename
        data {
          __typename
          id
          cardProgramId
          state
          embedUrl
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const getOfferQuery = graphql(/* GraphQL */ `
  query getOffer($id: String!) {
    getOffer(id: $id) {
      __typename
      ... on QueryGetOfferSuccess {
        __typename
        data {
          __typename
          id
          name
          description
          userId
          startDate
          endDate
          cardLoadAmount
          maxOptIns
          multiMediaImageUrl
          isActive
          customMessageRequest
          customMessageResponse
          status
          dedicatedOfferLinkUrl
          optIns
          redemptions
          authRules {
            __typename
            id
            allowedMerchants {
              __typename
              id
              acceptorId
              city
              country
              descriptor
              mcc
              state
              timeCreated
              timeUpdated
              userId
            }
          }
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const getOffersQuery = graphql(/* GraphQL */ `
  query offers($pageNumber: Int!, $offset: Int!, $otherUserId: String) {
    offers(pageNumber: $pageNumber, offset: $offset, otherUserId: $otherUserId) {
      __typename
      ... on QueryOffersSuccess {
        __typename
        data {
          __typename
          count
          offers {
            __typename
          id
          name
          description
          userId
          startDate
          endDate
          cardLoadAmount
          maxOptIns
          multiMediaImageUrl
          isActive
          customMessageRequest
          customMessageResponse
          status
          dedicatedOfferLinkUrl
          optIns
          redemptions
          }
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const getTransactionDataQuery = graphql(/* GraphQL */ `
  query getTransactionData {
    getTransactionData {
      __typename
      ... on QueryGetTransactionDataSuccess {
        __typename
        data {
          __typename
          cardTransactions
          transactionalVolume
          fees
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const getPaymentIntentQuery = graphql(/* GraphQL */ `
  query getPaymentIntent($id: String!) {
    getPaymentIntent(id: $id) {
      __typename
      ... on QueryGetPaymentIntentSuccess {
        __typename
        data {
          __typename
          clientSecret
          id
          amount
          status
          userId
          serviceId
          paymentMethod
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const listCardProgramsQuery = graphql(/* GraphQL */ `
query listCardPrograms($pageNumber: Int!, $offset: Int!, $otherUserId: String) {
  cardPrograms(pageNumber: $pageNumber, offset: $offset, otherUserId: $otherUserId) {
    __typename
    ... on QueryCardProgramsSuccess {
      __typename
      data {
        __typename
        count
        cardPrograms {
          __typename
          id
          userId
          phoneNumber
          issuer
          cardArtworkUrl
          timeCreated
          timeUpdated
          name
          totalCards
        }
      }
    }
    ... on Error {
      message
      __typename
    }
  }
}
`)

export const listApiKeysQuery = graphql(/* GraphQL */ `
  query listApiKeys {
    apiKeys {
      __typename
      ... on QueryApiKeysSuccess {
        __typename
        data {
          __typename
            id
            name
            userId
            enabled
            timeCreated
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)


export const primaryAccountQuery = graphql(/* GraphQL */ `
  query primaryAccount($userId: String) {
    primaryAccount(userId: $userId) {
      __typename
      ... on QueryPrimaryAccountSuccess {
        __typename
        data {
          __typename
          id
          balance
          userId
          type
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const funnelDataQuery = graphql(/* GraphQL */ `
query funnelData {
  funnelData {
    __typename
    ... on QueryFunnelDataSuccess {
    __typename
    data {
      __typename
      sent
      delivered
      viewed
      purchased
      }
    }
    ... on Error {
      message
      __typename
    }
  }
}
`)

export const getPublishableKeyQuery = graphql(/* GraphQL */ `
  query getPublishableKey {
    getPublishableKey {
      __typename
      ... on QueryGetPublishableKeySuccess {
        __typename
        data {
          publishableKey
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const sessionQuery = graphql(/* GraphQL */ `
query session {
  session {
    id
    phoneNumber
    email
    firstName
    lastName
    role
  }
}
`)

export const listTransactionsQuery = graphql(/* GraphQL */ `
  query transactions($pageNumber: Int!, $offset: Int!, $type: [String!]) {
    transactions(pageNumber: $pageNumber, offset: $offset, type: $type) {
      __typename
      ... on QueryTransactionsSuccess {
        data {
          __typename
          count
          transactions {
            __typename
            id
            accountId
            userId
            type
            timeCreated
            timeUpdated
            amount
          }
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`)

export const listUsersQuery = graphql(/* GraphQL */ `
  query users($pageNumber: Int!, $offset: Int!, $userType: [String!], $email: String, $phoneNumber: String) {
    users(pageNumber: $pageNumber, offset: $offset, userType: $userType, email: $email, phoneNumber: $phoneNumber) {
      __typename
      ... on QueryUsersSuccess {
        __typename
        data {
          __typename
          count
          users {
            __typename
            id
            phoneNumber
            email
            firstName
            lastName
            role
            timeCreated
          }
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const listOtherUserTransactionsQuery = graphql(/* GraphQL */ `
  query otherUserTransactions($pageNumber: Int!, $offset: Int!, $type: [String!], $otherUserId: String!) {
    otherUserTransactions(pageNumber: $pageNumber, offset: $offset, type: $type, otherUserId: $otherUserId) {
      ... on QueryOtherUserTransactionsSuccess {
        data {
          count
          transactions {
            id
            accountId
            userId
            type
            timeCreated
            timeUpdated
            amount
          }
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`)

export const listAccountsQuery = graphql(/* GraphQL */ `
  query accounts($pageNumber: Int!, $offset: Int!, $otherUserId: String!) {
    accounts(pageNumber: $pageNumber, offset: $offset, otherUserId: $otherUserId) {
      __typename
      ... on QueryAccountsSuccess {
        __typename
       data {
        __typename
        count
        accounts {
          id
          balance
          userId
          type
          timeUpdated
          timeCreated
          cardProgram {
            name
          }
        }
       }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const getUserQuery = graphql(/* GraphQL */ `
  query getUser($id: String!) {
    getUser(id: $id) {
      id
      phoneNumber
      email
      firstName
      lastName
      role
      timeCreated
      isSuspended
    }
  }
`)

export const getPricingQuery = graphql(/* GraphQL */ `
  query getPricing($id: String) {
    pricing(id: $id) {
      id
      userId
      cardCreation
      cardTransaction
      sms
      sendFunds
      monthlyFee
      plan
      offerOptIn
    }
  }
`)