import { FC } from 'react'
import IconProps from './Icon.interface'

const Pause: FC<IconProps> = ({
  color = '#F95454',
  width = 14,
  height = 14,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14
    3.136 10.864 0 7 0ZM7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6C3.913
    12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4ZM7 2.8C4.683 2.8 2.8 4.683 2.8 7C2.8
    9.317 4.683 11.2 7 11.2C9.317 11.2 11.2 9.317 11.2 7C11.2 4.683 9.317 2.8 7 2.8Z"
      fill={color}
    />
  </svg>
)

export default Pause
