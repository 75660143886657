import Active from './Active'
import Bars from './Bars'
import ChevronDown from './ChevronDown'
import Close from './Close'
import CloseCircle from './CloseCircle'
import Expiring from './Expiring'
import Help from './Help'
import Info from './Info'
import Pause from './Pause'
import Pending from './Pending'
import Phone from './Phone'
import Plus from './Plus'
import PlusCircle from './PlusCircle'
import Success from './Success'
import TriangleExclamation from './TriangleExclamation'
import Calendar from './Calendar'
import CloseBanner from './CloseBanner'
import ErrorBanner from './ErrorBanner'
import MoneyTag from './MoneyTag'
import Star from './Star'
import ArrowDown from './ArrowDown'
import Lock from './Lock'
import Visa from './Visa'
import MasterCard from './MasterCard'
import Discover from './Discover'
import JCB from './JCB'
import DinersClub from './DinersClub'
import AmericanExpress from './AmericanExpress'

const Icon = () => <span />
Icon.Active = Active
Icon.Bars = Bars
Icon.ChevronDown = ChevronDown
Icon.Close = Close
Icon.CloseCircle = CloseCircle
Icon.Expiring = Expiring
Icon.Help = Help
Icon.Info = Info
Icon.Pause = Pause
Icon.Pending = Pending
Icon.Phone = Phone
Icon.Plus = Plus
Icon.PlusCircle = PlusCircle
Icon.Success = Success
Icon.TriangleExclamation = TriangleExclamation
Icon.Calendar = Calendar
Icon.CloseBanner = CloseBanner
Icon.ErrorBanner = ErrorBanner
Icon.MoneyTag = MoneyTag
Icon.Star = Star
Icon.ArrowDown = ArrowDown
Icon.Lock = Lock
Icon.Visa = Visa
Icon.MasterCard = MasterCard
Icon.Discover = Discover
Icon.JCB = JCB
Icon.DinersClub = DinersClub
Icon.AmericanExpress = AmericanExpress

export default Icon
