import { graphql } from "./gql"

export const createMerchantCardMutation = graphql(/* GraphQL */ `
  mutation createMerchantCard {
    createMerchantCard {
      __typename
      ... on MutationCreateMerchantCardSuccess {
        __typename
        data {
          __typename
          id
          cardProgramId
          state
          embedUrl
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`)

export const addOfferPermissionsMutation = graphql(/* GraphQL */ `
  mutation addOfferPermissions($offerId: String!, $merchantIds: [String!]!) {
    addOfferPermissions(offerId: $offerId, merchantIds: $merchantIds) {
      __typename
      ... on MutationAddOfferPermissionsSuccess {
        __typename
        data {
          __typename
          id
          name
          description
          userId
          startDate
          endDate
          cardLoadAmount
          maxOptIns
          multiMediaImageUrl
          isActive
          customMessageRequest
          customMessageResponse
          status
          dedicatedOfferLinkUrl
          optIns
          redemptions
          authRules {
            __typename
            id
            allowedMerchants {
              __typename
              id
              acceptorId
              city
              country
              descriptor
              mcc
              state
            }
          }
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`)

export const createApiKeyMutation = graphql(/* GraphQL */ `
  mutation createApiKey($name: String!) {
    createApiKey(name: $name) {
      __typename
      ... on MutationCreateApiKeySuccess {
        __typename
        data {
          __typename
          enabled
          id
          name
          timeCreated
          userId
          key
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`);


export const deleteApiKeyMutation = graphql(/* GraphQL */ `
  mutation deleteApiKey($id: String!) {
    deleteApiKey(id: $id) {
      __typename
      ... on MutationDeleteApiKeySuccess {
        __typename
        data
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`);

export const createOfferMutation = graphql(/* GraphQL */ `
  mutation createOffer($name: String!, $description: String, $cardProgramId: String!, $startDate: String!, $endDate: String!, $cardLoadAmount: Float!, $maxOptIns: Int!, $customMessageRequest: String!, $customMessageResponse: String!, $useMultiMediaImage: Boolean) {
    createOffer(name: $name, description: $description, cardProgramId: $cardProgramId, startDate: $startDate, endDate: $endDate, cardLoadAmount: $cardLoadAmount, maxOptIns: $maxOptIns, customMessageRequest: $customMessageRequest, customMessageResponse: $customMessageResponse, useMultiMediaImage: $useMultiMediaImage) {
      __typename
      ... on MutationCreateOfferSuccess {
        __typename
        data {
          __typename
          id
          name
          description
          userId
          startDate
          endDate
          cardLoadAmount
          maxOptIns
          multiMediaImageUrl
          multiMediaImagePresignedUrl
          customMessageRequest
          customMessageResponse
          timeCreated
          timeUpdated
          isActive
          status
          dedicatedOfferLinkUrl
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`)

export const sendOfferMutation = graphql(/* GraphQL */ `
  mutation sendOffer($offerId: String!, $phoneNumber: String!) {
    sendOffer(offerId: $offerId, phoneNumber: $phoneNumber) {
      __typename
      ... on MutationSendOfferSuccess {
        __typename
        data {
          __typename
          lifecycleId
          message
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`)

export const updateOfferMutation = graphql(/* GraphQL */ `
  mutation updateOffer($id: String!, $name: String, $description: String, $startDate: String, $endDate: String, $cardLoadAmount: Float, $maxOptIns: Int, $customMessageRequest: String, $customMessageResponse: String, $useMultiMediaImage: Boolean, $isActive: Boolean) {
    updateOffer(id: $id, name: $name, description: $description, startDate: $startDate, endDate: $endDate, cardLoadAmount: $cardLoadAmount, maxOptIns: $maxOptIns, customMessageRequest: $customMessageRequest, customMessageResponse: $customMessageResponse, useMultiMediaImage: $useMultiMediaImage, isActive: $isActive) {
      __typename
      ... on MutationUpdateOfferSuccess {
        __typename
        data {
          __typename
          id
          name
          description
          userId
          startDate
          endDate
          cardLoadAmount
          maxOptIns
          multiMediaImageUrl
          multiMediaImagePresignedUrl
          customMessageRequest
          customMessageResponse
          timeCreated
          timeUpdated
          isActive
          status
          dedicatedOfferLinkUrl
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`)

export const addFundsToAccountMutation = graphql(/* GraphQL */ `
  mutation addFundsToAccount($id: String!, $balance: Float!, $userId: String!) {
    addFundsToAccount(id: $id, balance: $balance, userId: $userId) {
      __typename
      ... on MutationAddFundsToAccountSuccess {
        __typename
        data {
          __typename
          id
          balance
          type
          timeUpdated
          timeCreated
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const sendFundsMutation = graphql(/* GraphQL */ `
  mutation sendFunds($phoneNumber: String!, $cardProgramId: String!, $amount: Float!) {
    sendFunds(phoneNumber: $phoneNumber, cardProgramId: $cardProgramId, amount: $amount) {
      __typename
      ... on MutationSendFundsSuccess {
        __typename
        data {
          __typename
          lifecycleId
          message
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const createCardProgramMutation = graphql(/* GraphQL */ `
  mutation createCardProgram($name: String!, $userId: String!, $cardProgramNetworkId: String!, $phoneNumber: String!) {
    createCardProgram(name: $name, userId: $userId, cardProgramNetworkId: $cardProgramNetworkId, phoneNumber: $phoneNumber) {
      __typename
      ... on MutationCreateCardProgramSuccess {
        __typename
        data {
          __typename
          id
          name
          userId
          cardArtworkUrl
          cardProgramNetworkId
          timeCreated
          timeUpdated
          cardArtworkPresignedUrl
        }
      }
      ... on Error {
        message
        __typename
      }
    }
  }
`)

export const updatePhoneNumberChallengeMutation = graphql(/* GraphQL */ `
  mutation updatePhoneNumberChallenge($phoneNumber: String!) {
    updatePhoneNumberChallenge(phoneNumber: $phoneNumber) {
      __typename
      ... on MutationUpdatePhoneNumberChallengeSuccess {
        __typename
        data {
          __typename
          message
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const addPhoneNumberToUserMutation = graphql(/* GraphQL */ `
  mutation addPhoneNumberToUser($code: String!) {
    addPhoneNumberToUser(code: $code) {
      __typename
      ... on MutationAddPhoneNumberToUserSuccess {
        __typename
        data {
          __typename
          message
          phoneNumber
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const createPaymentIntentMutation = graphql(/* GraphQL */ `
  mutation createPaymentIntent($amount: Float!) {
    createPaymentIntent(amount: $amount) {
      __typename
      ... on MutationCreatePaymentIntentSuccess {
        __typename
        data {
          __typename
          clientSecret
          amount
          id
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`)

export const updatePricingMutation = graphql(/* GraphQL */ `
  mutation updatePricing($id: String!, $sms: Float, $cardCreation: Float, $monthlyFee: Float, $cardTransaction: Float, $sendFunds: Float) {
    updatePricing(id: $id, sms: $sms, cardCreation: $cardCreation, monthlyFee: $monthlyFee, cardTransaction: $cardTransaction, sendFunds: $sendFunds) {
      id
      cardCreation
      cardTransaction
      sms
      sendFunds
      monthlyFee
      userId
      plan
      offerOptIn
    }
  }
`)

export const createCustomPricingPlanMutation = graphql(/* GraphQL */ `
  mutation createCustomPricingPlan($userId: String!, $sms: Float!, $cardCreation: Float!, $monthlyFee: Float!, $cardTransaction: Float!, $sendFunds: Float!, $offerOptIn: Float!) {
    createCustomPricingPlan(userId: $userId, sms: $sms, cardCreation: $cardCreation, monthlyFee: $monthlyFee, cardTransaction: $cardTransaction, sendFunds: $sendFunds, offerOptIn: $offerOptIn) {
      id
      cardCreation
      cardTransaction
      sms
      sendFunds
      monthlyFee
      userId
      plan
      offerOptIn
    }
  }
`)

export const simulateAuthorizationMutation = graphql(/* GraphQL */ `
  mutation simulateAuthorization($amount: Float!, $descriptor: String!, $pan: String!, $merchant_acceptor_id: String!) {
    simulateAuthorization(amount: $amount, descriptor: $descriptor, pan: $pan, merchant_acceptor_id: $merchant_acceptor_id) {
      debugging_request_id
      token
    }
  }
`)

export const simulateVoidMutation = graphql(/* GraphQL */ `
  mutation simulateVoid($token: String!) {
    simulateVoid(token: $token) {
      debugging_request_id
    }
  }
`)

export const simulateClearingMutation = graphql(/* GraphQL */ `
  mutation simulateClearing($token: String!) {
    simulateClearing(token: $token) {
      debugging_request_id
    }
  }
`)

