export enum BasicModalWrapperVariants {
  PRIMARY = 'primary',
  GREY_WHITE_BG = 'greyWhiteBG',
  GREY_BOTTOM = 'greyBottom',
}

export default interface BasicModalWrapperProps {
  isOpen: boolean,
  onClose: () => void,
  children: JSX.Element,
  variant?: BasicModalWrapperVariants,
  isContentRightMargin?: boolean,
  maxHeight?: string,
}
