export enum ButtonVariants {
  PRIMARY = 'primary',
  PRIMARY_OUTLINE = 'primary-outline',
  SECONDARY = 'secondary',
  OUTLINE = 'outline',
  OUTLINE_SMALL = 'outline-small',
}

export default interface ButtonProps {
  onClick?: (e?: any) => void
  label: string
  variant: ButtonVariants
  disabled?: boolean
  className?: string
}
