import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const PlusCircleIcon: FunctionComponent<IconProps> = ({
  color = '#26346A',
  width = 37,
  height = 37,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="18.5" cy="18.5" r="18.5" fill="#80D4F3" />
    <path
      d="M24.264 20.0677H19.578V24.8197H17.708V20.0677H13.044V18.3737H17.708V13.5997H19.578V18.3737H24.264V20.0677Z"
      fill={color}
    />
  </svg>
)

export default PlusCircleIcon
