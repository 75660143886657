import { FC } from 'react'
import OfferPermissionsCardProps from './OfferPermissionsCard.interface'
import styles from './OfferPermissionsCard.module.scss'

const OfferPermissionsCard: FC<OfferPermissionsCardProps> = ({ editDetailsCallBack, descriptor, allowedMerchants }) => (
  <div className={`${styles.root}`}>
    <div className={`${styles.topContainer}`}>
      <div className={`${styles.titleWrapper}`}>
        <div className={`${styles.title}`}>
          Offer permissions
        </div>
      </div>
      <button
        type="button"
        onClick={editDetailsCallBack}
        className={`${styles.topCta}`}
      >
        Edit details
      </button>
    </div>
    <div className={`${styles.bottomContainer}`}>
      {/* <div className={`${styles.descriptor}`}>
        <div className={`${styles.descriptorTitle}`}>Descriptor contains</div>
        <div className={`${styles.descriptorText}`}>{descriptor}</div>
      </div> */}
      <div className={`${styles.allowedMerchants}`}>
        <div className={`${styles.allowedMerchantsTitle}`}>Allowed Merchants</div>
        <div className={`${styles.allowedMerchantsText}`}>{allowedMerchants}</div>
      </div>
    </div>
  </div>
)

export default OfferPermissionsCard
