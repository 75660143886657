import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const BarsIcon: FunctionComponent<IconProps> = ({
  color = '#ffffff',
  width = 32,
  height = 18,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="32" height="2" rx="1" fill={color} />
    <rect y="8" width="32" height="2" rx="1" fill={color} />
    <rect y="16" width="32" height="2" rx="1" fill={color} />
  </svg>
)

export default BarsIcon
