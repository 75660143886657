import React, { FC } from 'react'
import IconProps from './Icon.interface'

const ArrowDownIcon: FC<IconProps> = ({
  color = '#F95454',
  width = 12,
  height = 14,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 12 14"
    className={className}
  >
    <path
      fill={color}
      stroke="#F95454"
      strokeWidth="0.3"
      d="M.85 8.333c0 .17.068.335.2.46h.001l.102-.11-.102.11h0v.001l.002.002.007.006.028.025.104.098.381.356 1.243 1.159 2.703 2.523s0 0 0 0A.7.7 0 006 13.15a.701.701 0 00.481-.187l-.103-.11.103.11 2.703-2.522 1.242-1.16.38-.355.105-.097.027-.026.007-.006.002-.002h0l-.102-.11.102.11h0a.629.629 0 00.203-.461v-.001a.636.636 0 00-.205-.462.711.711 0 00-.96-.003h0l-3.3 3.082V1.5c0-.368-.316-.65-.685-.65-.369 0-.685.282-.685.65v9.45L2.013 7.867a.71.71 0 00-.958.004.633.633 0 00-.205.462zm0 0s0 0 0 0H1 .85s0 0 0 0z"
    />
  </svg>
)

export default ArrowDownIcon
