import Table from '../../components/Table'
import { useState, useMemo, useEffect } from 'react'
import { useQuery } from 'urql';
import { listLifecyclesQuery } from "../../graphql/queries"
import { Lifecycle } from '../../graphql/gql/graphql';
import { toast } from 'react-toastify';


export default function Page() {
  /* State */
  const [offset, setOffset] = useState<number>(0)

  /* Queries */
  const [{ data, fetching, error }] = useQuery({
    query: listLifecyclesQuery,
    variables: {
      offset,
      pageNumber: 10
    },
    requestPolicy: "network-only"
  })

  /* Application Based Error Handling */
  useEffect(() => {
    if (data?.lifecycles.__typename === "BaseError") {
      toast.error(data?.lifecycles.message)
      console.error(data?.lifecycles.message)
    }
  }, [
    data?.lifecycles
  ])

  /* GraphQL Error Handling */
  useEffect(() => {
    let errorMessage = ""
    if (error) errorMessage += `${error.message} `
    if (errorMessage) toast.error(errorMessage, {position: "top-center"})
  }, [
    error
  ])

  /* Lifecycle data keys and filtering */
  const itemsPerPage = 10;
  const keys: (keyof any)[] = useMemo(() => [
    "phoneNumber",
    "status",
    "timeCreated",
    "newCard"
  ], []);
  const filteredLifecycles = useMemo(() => {
    if (!data?.lifecycles) return [];
    if (data?.lifecycles.__typename == "BaseError") {
      return []
    }
    return data?.lifecycles.data.lifecycles.map((obj: any) => {
      const filteredObj: any = {};
      keys.forEach((key: keyof any) => {
        if (key in obj) {
          filteredObj[key] = obj[key];
        } else if (key === "phoneNumber") {
          filteredObj[key] = obj.card.user.phoneNumber;
        }
      });
      return filteredObj;
    });
  }, [data?.lifecycles, keys]);
  const count = useMemo(() => {
    if (!data?.lifecycles) return 0;
    if (data?.lifecycles.__typename == "BaseError") {
      return 0
    }
    return data?.lifecycles.data.count;
  }, [data?.lifecycles])

  return (
    <div className='m-10'>
      <h1 className='my-5'>Lifecycles</h1>
      <Table
        loading={fetching}
        columns={keys as any}
        data={filteredLifecycles}
        totalItems={count}
        itemsPerPage={itemsPerPage}
        onNextPage={() => {
          // logic to move to the next page
          setOffset(offset + itemsPerPage)
        }}
        onPrevPage={() => {
          // logic to move to the previous page
          setOffset(offset - itemsPerPage)
        }}
      />
    </div>
  )
}