import { FC } from 'react'
import IconProps from './Icon.interface'

const Expiring: FC<IconProps> = ({
  color = '#000000',
  width = 16,
  height = 16,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.9996 16C12.4151 16 16 12.4151 16 7.9996C16 3.5841 12.4151 0 7.9996
      0C3.5849 0 0 3.5841 0 7.9996C0 12.4151 3.5849 16 7.9996 16ZM7.9996 9.5984C7.66832
      9.5984 7.39945 9.32953 7.39945 8.99825V4.59715C7.39945 4.26587 7.66832
      3.997 7.9996 3.997C8.33088 3.997 8.59975 4.26587 8.59975 4.59715V8.99825C8.59975
      9.32953 8.33088 9.5984 7.9996 9.5984ZM7.998 11.999C7.55629 11.999 7.1978
      11.6405 7.1978 11.1988C7.1978 10.7571 7.55629 10.3986 7.998 10.3986C8.43971
      10.3986 8.7982 10.7571 8.7982 11.1988C8.7982 11.6405 8.43971 11.999 7.998 11.999Z"
      fill={color}
    />
  </svg>
)

export default Expiring
