import { FC, useState, useEffect } from 'react'
import OfferFormProps from './OfferForm.interface'
import styles from './OfferForm.module.scss'
import DatePicker from "../../components/DatePicker/DatePicker";
import DropZone from '../../components/DropZone';
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import SingleSelect from '../SingleSelect/SingleSelect';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { useMutation } from 'urql';
import { createOfferMutation, updateOfferMutation } from '../../graphql/mutations';
import { toast } from 'react-toastify';
import { redirect } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import * as yup from 'yup'
import { SubmitHandler, useForm, Controller, set } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

export interface ICreateOffer {
  name: string
  startDate: string
  endDate: string
  cardProgramId: string
  multiMediaImage?: File
  maxOptIns: number
  customMessageRequest: string
  customMessageResponse: string
  loadAmount: string
}

export interface IUpdateOffer {
  name?: string
  startDate?: string
  endDate?: string
  multiMediaImage?: File
  maxOptIns?: number
  customMessageRequest?: string
  customMessageResponse?: string
  loadAmount?: string
  cardProgramId?: string
}


const OfferForm: FC<OfferFormProps> = ({
  createOfferModalIsOpen,
  setCreateOfferModalIsOpen,
  cardPrograms,
  offerData
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [resetDropZone, setResetDropZone] = useState(false);

  const [createOfferData, setCreateOfferData] = useState<ICreateOffer>({
    name: offerData?.data.name ?? "",
    startDate: offerData?.data.startDate ?? "",
    endDate: offerData?.data.endDate ?? "",
    cardProgramId: "",
    maxOptIns: offerData?.data.maxOptIns ?? 0,
    customMessageRequest: offerData?.data.customMessageRequest ?? "",
    customMessageResponse: offerData?.data.customMessageResponse ?? "",
    loadAmount: offerData?.data.cardLoadAmount ? String(offerData.data.cardLoadAmount) : "0.00"
  })

  const [, createOffer] = useMutation(createOfferMutation)
  const [, updateOffer] = useMutation(updateOfferMutation)


  const createSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    startDate: yup.string().required('StartDate is required'),
    endDate: yup.string().required('EndDate is required'),
    loadAmount: yup.string().required('CardLoadAmount is required'),
    customMessageRequest: yup.string().required('CustomMessageRequest is required'),
    customMessageResponse: yup.string().required('CustomMessageResponse is required'),
    multiMediaImage: yup.mixed(),
    maxOptIns: yup.number().required('MaxOptins is required'),
    cardProgramId: yup.string().required('CardProgramId is required'),
  })

  const updateSchema = yup.object().shape({
    name: yup.string(),
    startDate: yup.string(),
    endDate: yup.string(),
    loadAmount: yup.string(),
    customMessageRequest: yup.string(),
    customMessageResponse: yup.string(),
    multiMediaImage: yup.mixed(),
    maxOptIns: yup.number(),
    cardProgramId: yup.string()
  })

  const formOptions = { resolver: yupResolver(offerData ? updateSchema : createSchema) }
  const { handleSubmit, formState: {errors}, control, reset, register } = useForm<ICreateOffer | IUpdateOffer>(formOptions)



  const doHandleSubmit = async () => {
    if (offerData) {
      onUpdateOfferSubmit()
    } else {
      onCreateOfferSubmit()
    }
  }

  /* Update Offer */
  const onUpdateOfferSubmit = async () => {
    const { cardProgramId, loadAmount, ...rest } = createOfferData
    setIsLoading(true)
    if (!offerData) {
      console.error("No offer data")
      return
    }
    updateOffer({
      id: offerData.data.id,
      useMultiMediaImage: createOfferData.multiMediaImage ? true : false,
      ...rest,
      cardLoadAmount: Number(loadAmount),
    }).then((result) => {
      if (result.data?.updateOffer.__typename === 'BaseError') {
        toast.error(result.data.updateOffer.message, {position: "top-center"})
        console.error(result.data.updateOffer.message)
        return
      }
      if (!result.data?.updateOffer.data.multiMediaImagePresignedUrl && createOfferData.multiMediaImage) {
        console.error("No multi media image presigned url")
        toast.error("No multi media image presigned url", {position: "top-center"})
        return
      } else if (createOfferData.multiMediaImage && result.data?.updateOffer.data.multiMediaImagePresignedUrl) {
        fetch(result.data.updateOffer.data.multiMediaImagePresignedUrl, {
          body: createOfferData.multiMediaImage,
          method: 'PUT',
          headers: {
            'Content-Type': createOfferData.multiMediaImage.type,
          }
        }).then((result) => {
          toast.success("Update Offer Successful", {position: "top-center"})
        }).catch((error) => {
          console.error(error)
          toast.error(error.message, {position: "top-center"})
          return
        })
      } else {
        toast.success("Update Offer Successful", {position: "top-center"})
      }
      setCreateOfferModalIsOpen(false)
    }).finally(() => {
      setIsLoading(false)
    })
  }


  /* Create Offer */
  const onCreateOfferSubmit = async () => {
    setIsLoading(true)
    createOffer({
      cardLoadAmount: Number(createOfferData.loadAmount),
      cardProgramId: createOfferData.cardProgramId,
      customMessageRequest: createOfferData.customMessageRequest,
      customMessageResponse: createOfferData.customMessageResponse,
      endDate: createOfferData.endDate,
      maxOptIns: createOfferData.maxOptIns,
      name: createOfferData.name,
      startDate: createOfferData.startDate,
      useMultiMediaImage: createOfferData.multiMediaImage ? true : false
    }).then((result) => {
      if (result.data?.createOffer.__typename === 'BaseError') {
        toast.error(result.data.createOffer.message, {position: "top-center"})
        console.error(result.data.createOffer.message)
        return
      }
      if (!result.data?.createOffer.data.multiMediaImagePresignedUrl && createOfferData.multiMediaImage) {
        console.error("No multi media image presigned url")
        toast.error("No multi media image presigned url", {position: "top-center"})
        return
      } else if (createOfferData.multiMediaImage && result.data?.createOffer.data.multiMediaImagePresignedUrl) {
        fetch(result.data.createOffer.data.multiMediaImagePresignedUrl, {
          body: createOfferData.multiMediaImage,
          method: 'PUT',
          headers: {
            'Content-Type': createOfferData.multiMediaImage.type,
          }
        }).then((result) => {
          toast.success("Create Offer Successful", {position: "top-center"})
        }).catch((error) => {
          console.error(error)
          toast.error(error.message, {position: "top-center"})
          return
        })
      } else {
        toast.success("Create Offer Successful", {position: "top-center"})
      }
      redirect(`/offers/${result.data?.createOffer.data.id}`)
    }).catch((error) => {
      toast.error(error.message, {position: "top-center"})
      console.error(error)
    }).finally(() => {
      setResetDropZone(true)
      setCreateOfferModalIsOpen(false)
      setCreateOfferData({
        name: "",
        startDate: "",
        endDate: "",
        cardProgramId: "",
        maxOptIns: 0,
        customMessageRequest: "",
        customMessageResponse: "",
        loadAmount: "0/00"
      })
      setIsLoading(false)
    })
  }

  // Reset the trigger after the DropZone has been reset
  useEffect(() => {
    if (resetDropZone) {
      setResetDropZone(false);
    }
  }, [resetDropZone]);


  return (
    <Modal isOpen={createOfferModalIsOpen} title="Create Offer" onClose={() => setCreateOfferModalIsOpen(false)}>
      <div className="m-5">
          <Input 
            name='name'
            label='Name*'
            register={register}
            validation={{ required: true }}
            value={createOfferData.name}
            onChangeValue={(data) => setCreateOfferData({...createOfferData, name: data})}
            placeholder="Enter a name for your offer"
            errorText={errors.name?.message}
          />
          {cardPrograms !== undefined && 
            <Controller 
            name='cardProgramId'
            control={control}
            defaultValue={createOfferData.cardProgramId}
            render={({ field: { onChange, name, ref }}) => (
              <SingleSelect 
                  label='Card program'
                  defaultValue={createOfferData.cardProgramId}
                  options={cardPrograms ?? []}
                  hasError={!!errors.cardProgramId}
                  errorMessage={errors.cardProgramId?.message}
                  placeholder='Select Card Program'
                  onChange={(val: { value: string; label: string; }) => {
                      onChange(val.value)
                      setCreateOfferData({...createOfferData, cardProgramId: val.value})
                  }}
                  isRequired
                  isSearchable
                  isClearable={false}
                  customClass={styles.input}
                  innerRef={ref}
                  name={name}
              />
            )}
          />
          }
          <div className="grid grid-cols-2 gap-3">
            <div className="grid-span-1">
              <DatePicker 
                name="startDate"
                label='Start Date*'
                placeholder="Enter a start date"
                defaultValue={createOfferData.startDate ?? ""}
                onChangeValue={(data: string) => setCreateOfferData({...createOfferData, startDate: data})}
                register={register}
                validation={{required: true}}
                
              />
            </div>
            <div>
              <DatePicker 
                name="endDate"
                label='End Date*'
                placeholder="Enter a end date"
                defaultValue={createOfferData.endDate ?? ""}
                onChangeValue={(data: string) => setCreateOfferData({...createOfferData, endDate: data})}
                register={register}
                validation={{required: true}}
                
              />
            </div>
          </div>
          <Input 
            type="number"
            value={createOfferData.maxOptIns}
            onChangeValue={(data) => setCreateOfferData({...createOfferData, maxOptIns: parseInt(data)})}
            placeholder="enter a number for max optins"
            name='maxOptIns'
            label='Max Optins*'
            register={register}
            validation={{ required: true }}
            errorText={errors.maxOptIns?.message}
          />
          <Input 
            label="Custom Message Request*"
            value={createOfferData.customMessageRequest}
            onChangeValue={(data) => setCreateOfferData({...createOfferData, customMessageRequest: data})}
            placeholder="Enter a custom message request"
            name='customMessageRequest'
            register={register}
            validation={{ required: true }}
            errorText={errors.customMessageRequest?.message}
          />
          <Input 
            label="Custom Message Response*"
            value={createOfferData.customMessageResponse}
            onChangeValue={(data) => setCreateOfferData({...createOfferData, customMessageResponse: data})}
            placeholder="Enter a custom message response"
            name='customMessageResponse'
            register={register}
            validation={{ required: true }}
            errorText={errors.customMessageResponse?.message}
          />
          <CurrencyInput
            name="loadAmount"
            placeholder="Please enter a dollar amount"
            label='Load Amount*'
            onChangeValue={(data) => setCreateOfferData({...createOfferData, loadAmount: data})}
            register={register}
            validation={{ required: true }}
            errorText={errors.loadAmount?.message}
            value={String(createOfferData.loadAmount)}
          />
          <DropZone 
            label="Multi Media Image (Optional)"
            name='multiMediaImage'
            register={register}
            validation={{ required: false }}
            onFileChange={(file) => setCreateOfferData({...createOfferData, multiMediaImage: file})}
            resetTrigger={resetDropZone}
          />
          <div className="flex justify-end mt-2">
          {isLoading ?
            <div className="my-3"><Spinner /></div> 
          :
            <button onClick={() => handleSubmit(doHandleSubmit)()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
          }
        </div>
      </div>
  </Modal>
  )
}

export default OfferForm
