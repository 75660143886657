import { useState } from 'react'
import styles from './verifyPhoneNumber.module.scss'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { updatePhoneNumberChallengeMutation } from "../../graphql/mutations"
import { useMutation } from 'urql';
import { useNavigate } from 'react-router-dom';

import Spinner from '../../components/Spinner'
import { toast } from 'react-toastify';
export default function VerifyPhoneNumber() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [, phoneNumberChallenge] = useMutation(updatePhoneNumberChallengeMutation)


  const onSubmit = async () => {
    setIsLoading(true)
    phoneNumberChallenge({ phoneNumber: `+${phoneNumber}` }).then((result) => {
      if (result.data?.updatePhoneNumberChallenge.__typename === "MutationUpdatePhoneNumberChallengeSuccess") {
        setIsLoading(false)
        navigate('/verify-phone-number-complete')
      } else {
        toast.error(result.data?.updatePhoneNumberChallenge.message)
      }
    }).catch((error) => {
      toast.error(error.message)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <div className={styles.root}>
      <h1 className="text-grey dark:text-gray-300 pb-5">Add Phone Number</h1>
      <p className="dark:text-gray-300 pb-5 typo-p-small">
        We require a phone number to help keep your data in sync. Enter your phone number below to receive a verification code.
        </p>
        <div>
        <PhoneInput
          placeholder="Enter phone number"
          inputClass={styles['input-phone-number']}
          containerClass={styles['container-phone-number']}
          country={'us'}
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value)}
        />
        <div className="flex justify-end mt-2">
          {isLoading ?
            <div className="my-3"><Spinner /></div> 
          :
            <button type="button" onClick={() => onSubmit()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
          }
        </div>
        </div>
    </div>
  )
}