import { FC } from 'react'
import { useAuth } from '../../providers/Auth'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import PrivateRouteProps from './PrivateRoute.interface'

const PrivateRoute: FC<PrivateRouteProps> = () => {
  const { session, userData } = useAuth()
  const location = useLocation()

  // Check if user is logged in
  if (!session) {
    return <Navigate to="/login" />
  }

  // Check if user needs to verify their phone number, and they are not already on the verification page
  if (userData && !userData?.phoneNumber && location.pathname !== '/verify-phone-number' && location.pathname !== '/verify-phone-number-complete') {
    return <Navigate to="/verify-phone-number" />
  }

  return <Outlet />
}

export default PrivateRoute
